/* eslint-disable no-console */
import api from "./api";

const fetchAllRegions = ({ callback }) => {
  api({
    Method: "GET",
    Url: "/region",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];
        callback(data);
      }
    },
  });
};

const fetchRegionalCongressman = ({ params, callback }) => {
  api({
    Url: `/region/${params}/congressmen`,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];
        callback(data);
      }
    },
  });
};

const updateRowDetails = ({ formData, params, callback }) => {
  api({
    Method: "PUT",
    Url: `/recipient/${params}`,
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response?.status === 200) {
        callback({
          isSuccess: true,
        });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: false });
    },
  });
};

const fetchCongressmanProjects = ({ url, params, callback }) => {
  api({
    Method: "GET",
    Params: params,
    Url: url,
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];

        callback(data);
      }
    },
  });
};

const fetchCongressmanDetails = ({ params, callback }) => {
  api({
    Url: `/congressman/${params}`,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : {};
        callback(data);
      }
    },
  });
};

const fetchCommiteeDetails = ({ params, callback }) => {
  api({
    Method: "GET",
    Url: `/congressman/${params}/committees`,
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : {};
        callback(data);
      }
    },
  });
};

const fetchComments = ({ params, callback }) => {
  api({
    Method: "GET",
    Url: `/recipient/${params}/comments`,
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];
        callback(data);
      }
    },
  });
};

const fetchAgencyBudgetAllocation = ({ params, callback }) => {
  const { cong, agency } = params;

  api({
    Method: "GET",
    Url: `/congressman/${cong}/allocatedBudget/${agency}`,
    Params: params,
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        callback(response.data);
      }
    },
  });
};

const fetchRegions = ({ callback }) => {
  api({
    Method: "GET",
    Url: "/region",
    ResponseSuccessCallback: (response) => {
      if (response && response?.status === 200) {
        const { data } = response;
        callback(data);
      }
    },
  });
};

const fetchDistricts = async ({ callback }) => {
  await api({
    Method: "GET",
    Url: "/district",
    ResponseSuccessCallback: (response) => {
      if (response && response?.status === 200) {
        const { data } = response;
        callback(data);
      }
    },
  });
};

const editCongDetails = async ({ url, formData, callback }) => {
  await api({
    Method: "PUT",
    Url: url,
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response.status === 200) {
        callback({ isSuccess: true });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: false });
    },
  });
};

const deleteCongressman = async ({ param, callback }) => {
  await api({
    Url: `/congressman/${param}`,
    Method: "DELETE",
    ResponseSuccessCallback: (response) => {
      if (response && response?.status === 200) {
        callback({ isSuccess: true });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: false });
    },
  });
};

const addCongressman = async ({ formData, callback }) => {
  await api({
    Method: "POST",
    Url: "/congressman",
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response.status === 200) {
        callback({
          isSuccess: true,
          msg: response.data,
        });
      } else {
        callback({
          isSuccess: false,
          msg: response.data,
        });
      }
    },
    ResponseFailCallback: (err) => {
      callback({
        isSuccess: false,
        msg: err.response.data,
      });
    },
  });
};

const fetchAllUpdateLogs = async ({ param, callback }) => {
  await api({
    Method: "GET",
    Url: `/request-log?method=PUT&table=Congressmen&referenceId=${param}`,
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length ? response?.data : [];
        callback(data);
      }
    },
  });
};

const fetchAllCongressmen = async ({ callback }) => {
  await api({
    Method: "GET",
    Url: "/congressman/?type=getall",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length ? response?.data : [];
        callback(data);
      }
    },
  });
};

export {
  fetchAllRegions,
  fetchRegionalCongressman,
  updateRowDetails,
  fetchCongressmanProjects,
  fetchCongressmanDetails,
  fetchCommiteeDetails,
  fetchComments,
  fetchAgencyBudgetAllocation,
  fetchRegions,
  fetchDistricts,
  editCongDetails,
  deleteCongressman,
  addCongressman,
  fetchAllUpdateLogs,
  fetchAllCongressmen,
};
