import BreadCrumbComponent from "components/customs/breadCrumb";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  changeUserPassword,
  getFormattedContactNumber,
  getFormattedOptions,
  Toast,
} from "utility/helpers";
import { getUserDetails, setUserDetails } from "config";
import { AddAccount, fetchAllRoles } from "services/services.accounts";
import {
  fetchAllAgencies,
  fetchAllAgencyProjects,
} from "services/services.projects";
import { congressmanSearch } from "services/services.navbar";
import _ from "lodash";
import AccountForm from "components/forms/Account";
import { congRoles } from "utility/constants";
import {
  AccountsTableBackground,
  AccountsTableContainer,
  BreadCrumbSection,
  FormSection,
  HeaderActions,
} from "../styled";

const Account = () => {
  const location = useLocation();
  const { action } = location.state;
  const userDetails = getUserDetails();
  const row = location.state?.row || null;

  const [accountTypeError, setAccountTypeError] = useState("");
  const [agencyError, setAgencyError] = useState("");
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [agencyOptions, setAgencyOptions] = useState([]);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [accountTypeOptions, setAgencyTypeOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [agenciesError, setAgenciesError] = useState("");
  const [agenciesOptions, setAgenciesOptions] = useState([]);
  const [programsError, setProgramsError] = useState("");
  const [agencyPrograms, setAgencyPrograms] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);

  const [selectedCongressman, setSelectedCongressman] = useState(null);
  const [congressmanError, setCongressmanError] = useState("");

  const [formFields, setFormFields] = useState({
    lastname: "",
    firstname: "",
    email: "",
    mobile: "",
    password: "",
    passwordConfirmation: "",
    agencyType: "",
    agency: "",
    userId: "",
    agencies: [
      {
        label: "All Agencies",
        value: "all",
        acronym: "All Agencies",
      },
    ],
    programs: [],
  });

  const navigate = useNavigate();

  const isRoleCongressman =
    userDetails.type === "Congressman" || userDetails.type === "COS";

  const cong = userDetails?.congressman || null;

  const validate = () => {
    if (
      (selectedAccountType?.value === 7 || selectedAccountType?.value === 6) &&
      selectedCongressman === null
    ) {
      setCongressmanError("Required");

      return "Required congressman";
    }
    setCongressmanError("");

    if (selectedAccountType === null) {
      setAccountTypeError("Required");

      return "Required role";
    }
    setAccountTypeError("");

    if (
      action !== "edit" &&
      selectedAccountType?.value === 2 &&
      selectedAgency === null
    ) {
      setAgencyError("Required");

      return "Required agency type";
    }
    setAgencyError("");

    if (
      selectedAccountType?.value !== 4 &&
      selectedAccountType?.value !== 2 &&
      formFields.agencies.length === 0
    ) {
      setAgenciesError("Required");

      return "Required agencies";
    }
    setAgenciesError("");

    if (selectedAccountType?.value === 2 && formFields.programs.length === 0) {
      setProgramsError("Required");

      return "Required program";
    }

    setProgramsError("");

    if (
      action === "add" &&
      (formFields?.password?.length === 0 ||
        formFields?.passwordConfirmation?.length === 0)
    ) {
      return "Required password / password confirmation";
    }

    if (
      formFields?.email?.length !== 0 &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formFields.email)
    ) {
      return "Invalid email format";
    }

    if (!/^(639)\d{9}$/.test(formFields?.mobile)) {
      return "Invalid mobile format";
    }

    if (
      formFields?.password?.length !== 0 &&
      (!/(?=.*?[A-Z])/.test(formFields?.password) ||
        !/(?=.*?[a-z])/.test(formFields?.password) ||
        !/(?=.*?[0-9])/.test(formFields?.password) ||
        !/(?=.*?[#?!@$%^&*-])/.test(formFields?.password) ||
        !/.{8,}/.test(formFields?.password))
    ) {
      return "Invalid password format. At least one uppercase, one lowercase, one digit, one special character, and at least 8 characters";
    }

    if (
      action === "add" &&
      formFields.password !== formFields.passwordConfirmation
    ) {
      return "Passwords must match";
    }

    return true;
  };

  const sendData = (formData) => {
    AddAccount({
      url: action === "add" ? "/auth/signup" : "/auth/update-user",
      method: action !== "add" ? "PUT" : "POST",
      formData,
      // eslint-disable-next-line consistent-return
      callback: ({ isSuccess, msg }) => {
        if (isSuccess) {
          if (action !== "view") return navigate("/accounts");

          const newUserDetails = {
            ...userDetails,
            email: formData.username,
            firstName: formData.firstName,
            lastName: formData.lastName,
            phoneNumber: formData.phoneNumber,
            type: selectedAccountType?.label,
            agency: selectedAgency?.name,
          };

          setUserDetails(newUserDetails);
          setIsLoading(false);

          Toast({
            type: 1,
            content: msg,
          });
        } else {
          setIsLoading(false);

          Toast({
            type: 4,
            content: msg,
          });
        }
      },
    });
  };

  const handleOnSumbmit = async () => {
    const isValid = await validate();

    if (typeof isValid === "boolean") {
      const getSelectedAgencies = () => {
        let agency = null;

        if (
          selectedAccountType?.value !== 4 &&
          selectedAccountType?.value !== 2
        ) {
          if (formFields.agencies.some((_agency) => _agency.value === "all")) {
            agency = agencyOptions.map((_agency) => _agency.value);
          } else {
            agency = formFields.agencies.map((_agency) => _agency.value);
          }
        }

        return agency;
      };

      const getSelectedPrograms = () => {
        let program = null;

        if (selectedAccountType?.value === 2) {
          if (
            formFields.programs.some((_program) => _program.value === "all")
          ) {
            program = programOptions
              .filter((_program) => _program.value !== "all")
              .map((_program) => _program.value);
          } else {
            program = formFields.programs.map((_program) => _program.value);
          }
        }

        return program;
      };

      const formData = {
        username: formFields.email,
        firstName: formFields.firstname,
        lastName: formFields.lastname,
        phoneNumber: formFields.mobile.slice(2),
        agency: selectedAccountType?.value === 2 ? selectedAgency?.value : null,
        accountType: selectedAccountType?.value,
        congressmanId: selectedCongressman?.value || null,
        agencyIds: getSelectedAgencies(),
        programIds: getSelectedPrograms(),
      };

      if (action === "add") formData.password = formFields.password;

      if (action === "edit") {
        changeUserPassword(formFields.email, formFields?.password);
      }

      if (action !== "add") formData.userId = formFields.userId;

      setIsLoading(true);
      await sendData(formData);
    } else {
      Toast({
        type: 4,
        content: isValid,
      });
    }
  };

  const fetchAgencies = () => {
    fetchAllAgencies({
      callback: (data) => {
        const newData = getFormattedOptions("name", data);

        const options = [
          {
            label: "All Agencies",
            value: "all",
            acronym: "All Agencies",
            id: "all",
          },
          ...newData,
        ];
        setAgencyOptions(newData);
        setAgenciesOptions(options);
      },
    });
  };

  const fetchRoles = async () => {
    await fetchAllRoles({
      callback: (data) => {
        if (data?.length !== 0) {
          const options = data
            .filter((option) =>
              userDetails.type === "Congressman" && action === "add"
                ? option.id === 7
                : option
            )
            .map((option) => {
              return {
                label: option?.name,
                value: option?.id,
              };
            });

          setAgencyTypeOptions(options);

          if (options.length === 1) {
            // set account type and selected congressman
            // when user is congressman
            setSelectedAccountType(options[0]);

            setSelectedCongressman({
              label: cong.name,
              value: cong.id,
            });
          }
        }
      },
    });
  };

  const handleCongressmanSearch = _.debounce((inputValue, callback) => {
    congressmanSearch({
      inputValue,
      callback,
    });
  }, 1000);

  const resetForm = () => {
    setFormFields({
      ...formFields,
      lastname: "",
      firstname: "",
      email: "",
      mobile: "",
      password: "",
      passwordConfirmation: "",
      agencyType: "",
      agency: "",
      userId: "",
      fullname: "",
    });
  };

  const prefillForm = () => {
    if (row && congRoles.includes(row.type)) {
      setSelectedCongressman({
        label: row?.congressmanName,
        value: row.congressmanId,
      });
    }

    setFormFields({
      ...formFields,
      firstname: row?.firstName ?? userDetails?.firstName,
      lastname: row?.lastName ?? userDetails?.lastName,
      mobile: getFormattedContactNumber(
        row?.phoneNumber ?? userDetails?.phoneNumber
      ),
      email: row?.email ?? userDetails?.email,
      agencyType: row?.type ?? userDetails?.type,
      agency: row?.agency ?? userDetails?.agency,
      userId: row?.userId ?? userDetails?.userId,
    });
  };

  const fetchPrograms = () => {
    fetchAllAgencyProjects({
      url: `/agency/${selectedAgency?.value}`,
      callback: (data) => {
        let projects = data?.Projects;
        let options = projects;

        if (options.length !== 0) {
          options = getFormattedOptions("name", projects);
          projects = getFormattedOptions("name", projects);

          options.unshift({
            label: "All Programs",
            value: "all",
          });
        }

        setAgencyPrograms(projects);
        setProgramOptions(options);
      },
    });
  };

  useEffect(() => {
    if (selectedAgency && selectedAgency?.value !== "all") {
      fetchPrograms();
    }
  }, [selectedAgency]);

  useEffect(() => {
    //  fetch all agencies
    fetchAgencies();

    // fetch role options
    fetchRoles();
  }, []);

  useEffect(() => {
    if (action !== "add") {
      prefillForm();
    }
  }, [action]);

  useEffect(() => {
    if (
      accountTypeOptions?.length &&
      formFields.agencyType !== "" &&
      !selectedAccountType
    ) {
      const agencyType = formFields?.agencyType;

      const option = accountTypeOptions.find(
        (agency) => agency.label === agencyType
      );
      setSelectedAccountType(option);

      if (isRoleCongressman) {
        setSelectedCongressman({
          value: cong.id,
          label: `HON. ${cong.name}`,
        });
      }
    }
  }, [accountTypeOptions, formFields]);

  useEffect(() => {
    const isIncluded = () => {
      const agencies = row ? row?.agencyIds : userDetails?.agencyIds;

      return formFields.agencies.every((agency) =>
        agencies?.includes(agency.value)
      );
    };

    if (
      agencyOptions?.length !== 0 &&
      action !== "add" &&
      selectedAccountType?.value !== 2 &&
      !isIncluded()
    ) {
      const agencies = row ? row.agencyIds : userDetails.agencyIds;

      const selectedAgencies = [];

      agencies?.forEach((agency) => {
        return agencyOptions.forEach((option) => {
          if (option.value === agency) {
            selectedAgencies.push(option);
          }
        });
      });

      if (selectedAgencies.length !== 0) {
        setFormFields({
          ...formFields,
          agencies: selectedAgencies,
        });
      }
    }
  }, [agencyOptions]);

  useEffect(() => {
    const isIncluded = () => {
      const programs = row ? row?.programIds : userDetails?.programIds;

      return formFields.programs.every((program) =>
        programs?.includes(program.value)
      );
    };

    if (
      action !== "add" &&
      agencyPrograms.length !== 0 &&
      selectedAccountType?.value === 2 &&
      !isIncluded()
    ) {
      const programs = row ? row.programIds : userDetails.programIds;

      const selectedPrograms = [];

      programs?.forEach((prog) => {
        return agencyPrograms.forEach((program) => {
          if (program.value === prog) {
            selectedPrograms.push(program);
          }
        });
      });

      if (selectedPrograms.length !== 0) {
        setFormFields({
          ...formFields,
          programs: selectedPrograms,
        });
      }
    }
  }, [agencyPrograms]);

  useEffect(() => {
    if (
      selectedAccountType &&
      action !== "add" &&
      selectedAccountType?.value === 2 &&
      agencyOptions.length !== 0 &&
      !selectedAgency
    ) {
      const agency = row ? row.agency : userDetails.agency;

      const option = agencyOptions.find((op) => op.label === agency);

      if (option) setSelectedAgency(option);
    }
  }, [selectedAccountType, agencyOptions]);

  useEffect(() => {
    if (
      selectedAccountType &&
      selectedAccountType?.value === 2 &&
      action === "add"
    ) {
      setSelectedAgency(null);
    }

    if (selectedAccountType && action === "add") {
      resetForm();
    }
  }, [selectedAccountType]);

  return (
    <AccountsTableBackground>
      <AccountsTableContainer>
        <BreadCrumbSection>
          <BreadCrumbComponent
            links={[
              {
                name: "Home",
                redirect: isRoleCongressman
                  ? `/representatives/${cong.region.id}/cong/${cong.id}`
                  : "/home",
              },
              {
                name: action === "add" ? "Accounts" : "Profile",
                redirect:
                  // eslint-disable-next-line no-nested-ternary
                  action === "add"
                    ? userDetails.type === "Congressman"
                      ? "/accounts"
                      : "/account"
                    : "/account",
              },
              {
                name: action === "add" ? "Add Account" : "Account Details",
                isActive: true,
              },
            ]}
          />
        </BreadCrumbSection>
        <HeaderActions>
          <h2 className="pb-0 mb-0 text-danger">
            {action === "add" ? "Add Account" : "Account Details"}
          </h2>
        </HeaderActions>
        <FormSection>
          <AccountForm
            action={action}
            selectedAccountType={selectedAccountType}
            handleOnSumbmit={handleOnSumbmit}
            formFields={formFields}
            accountTypeError={accountTypeError}
            setSelectedAccountType={setSelectedAccountType}
            accountTypeOptions={accountTypeOptions}
            setSelectedCongressman={setSelectedCongressman}
            handleCongressmanSearch={handleCongressmanSearch}
            selectedCongressman={selectedCongressman}
            congressmanError={congressmanError}
            setFormFields={setFormFields}
            agencyOptions={agencyOptions}
            selectedAgency={selectedAgency}
            setSelectedAgency={setSelectedAgency}
            agencyError={agencyError}
            isLoading={isLoading}
            agenciesError={agenciesError}
            agenciesOptions={agenciesOptions}
            programsError={programsError}
            programOptions={programOptions}
            agencyPrograms={agencyPrograms}
          />
        </FormSection>
      </AccountsTableContainer>
    </AccountsTableBackground>
  );
};

export default Account;
