import React, { useEffect, useState } from "react";
import BreadCrumbComponent from "components/customs/breadCrumb";
import {
  Star,
  CreditCard,
  Briefcase,
  Hash,
  Download,
  Edit2,
  Trash2,
  Plus,
  Settings,
} from "react-feather";
import CustomSelect from "components/customs/dropdown";
import { Link } from "react-router-dom";
import { getUserDetails } from "config";
import { fetchAllCongressmen } from "services/services.congressman";
import {
  fetchAllAgencies,
  fetchAllAgencyProjects,
} from "services/services.projects";
import {
  deleteUtilizationReport,
  fetchOthersList,
  fetchUtilizationReports,
} from "services/services.utilization";
import Swal from "sweetalert2";
import {
  DropdownIndicator,
  addProjectDropdownStyles,
  congRoles,
} from "utility/constants";
import {
  hasPermission,
  getFormattedDateString,
  getFormattedOptions,
  getFormattedNumber,
  Toast,
} from "utility/helpers";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
} from "reactstrap";
import SettingsModal from "components/customs/settings-modal";
import { useNavigate } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import SearchBarComponent from "components/customs/searchbarV2";
import ImageVideoSlider from "components/customs/image-video-slider";
import { downloadS3File } from "services/services.general";
// eslint-disable-next-line import/no-extraneous-dependencies
import JSZip from "jszip";
import {
  BreadCrumbSection,
  HeaderTextSection,
  MainContainer,
  Container,
  AddButton,
} from "./styled";
import "./styles.scss";
import noImgAvailable from "../../assets/img/noimgavail.png";

const UtilizationView = () => {
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedPrincipal, setSelectedPrincipal] = useState(null);
  const [selectedOthers, setSelectedOthers] = useState(null);
  const [projectOptions, setProjectOptions] = useState([]);
  const [congressmenOptions, setCongressmenOptions] = useState([]);
  const [agencyOptions, setAgencyOptions] = useState([]);
  const [othersOptions, setOthersOptions] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [images, setImages] = useState([]);
  const [displayOthersFilter, setDisplayOthersFilter] = useState(false);
  const [disablePrincipal, setdisablePrincipal] = useState(false);
  const [utilizationReports, setUtilizationReports] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalAllocation, setTotalAllocation] = useState(0);
  const [totalLiquidation, setTotalLiquidation] = useState(0);
  const [unliquidatedBalance, setUnliquidatedBalance] = useState(0);
  const [noOfReports, setNoOfReports] = useState(0);
  const [isModalShown, setIsModalShown] = useState(false);
  const [isAgencyChanged, setIsAgencyChanged] = useState(false);
  const [isAllAgency, setIsAllAgency] = useState(true);

  const toggleModal = () => setIsModalShown(!isModalShown);

  let changeSearchTimeoutID;

  const PRINCIPAL_IDS = [5, 234, 235];

  const navigate = useNavigate();

  const userDetails = getUserDetails();

  const toggleCarousel = () => setShowOverlay(!showOverlay);

  const fetchAgencies = async () => {
    await fetchAllAgencies({
      callback: (data) => {
        let newData = getFormattedOptions("name", data);

        if (userDetails.type === "Director" || userDetails.type === "Editor") {
          const allOption = { id: 0, label: "All" };
          newData = [allOption, ...newData];
        }

        setAgencyOptions(newData);

        setSelectedAgency(newData[0]);
      },
    });
  };

  const fetchProjects = async () => {
    await fetchAllAgencyProjects({
      url: `/agency/${selectedAgency?.value}`,
      callback: (data) => {
        let projects = data?.Projects;

        if (selectedAgency?.value === 14) {
          projects = data?.Projects.filter(
            (project) => project.name === "Infrastructure"
          );
        }

        let options = getFormattedOptions("name", projects);

        if (userDetails.type === "Director" || userDetails.type === "Editor") {
          const allOption = { id: 0, label: "All" };
          options = [allOption, ...options];
        }

        if (selectedAgency?.value === 14) setSelectedProject(options[0]);
        setProjectOptions(options);
        setSelectedProject(options[0]);
      },
    });
  };

  const fetchCongressmen = async () => {
    await fetchAllCongressmen({
      callback: (data) => {
        let newData = getFormattedOptions("name", data);

        if (userDetails.type === "Director" || userDetails.type === "Editor") {
          const allOption = { id: 0, label: "All" };
          newData = [allOption, ...newData];
        }

        setCongressmenOptions(newData);

        if (
          userDetails.type === "Director" ||
          userDetails.type === "Editor" ||
          userDetails.type === "Agency"
        ) {
          const defaultCong = newData.find((cong) => cong.id === 0);
          setDisplayOthersFilter(true);
          setSelectedPrincipal(defaultCong);
        } else if (congRoles.includes(userDetails.type)) {
          setdisablePrincipal(true);

          const defaultCong = newData.find(
            (cong) => cong.id === userDetails.congressman.id
          );
          setSelectedPrincipal(defaultCong);
        }
      },
    });
  };

  const fetchOthers = async (congId) => {
    await fetchOthersList({
      url: `/utilization/others/list?congressmanId=${congId}`,

      callback: (data) => {
        const newData = getFormattedOptions("allocationContactName", data);
        setOthersOptions(newData);
      },
    });
  };

  const fetchDataTable = async () => {
    const congId = selectedPrincipal?.value;
    const agencId = selectedAgency?.value;
    const projId = selectedProject?.value;
    const others = selectedOthers?.allocationContactName;

    await fetchUtilizationReports({
      url: "/utilization?limit=100&offset=0&column=createdAt&order=DESC",
      params: {
        congressmanId: congId,
        projectId: projId,
        agencyId: agencId,
        principalOthers: others,
      },
      callback: (data) => {
        setUtilizationReports(data.rows);
        setTotalAllocation(data.statistics.totalAllocation);
        setTotalLiquidation(data.statistics.totalLiquidation);
        setUnliquidatedBalance(data.statistics.unLiquidated);
        setNoOfReports(data.count);
      },
    });
  };

  const handleDeleteRow = (row) => {
    Swal.fire({
      title: "Warning",
      // eslint-disable-next-line max-len
      text: `Are you sure you want to delete ${row?.title}`,
      icon: "warning",
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteUtilizationReport({
          param: row.id,
          callback: ({ isSuccess }) => {
            if (isSuccess) {
              fetchDataTable();

              Toast({
                type: 1,
                content: "Successfully deleted report.",
              });
            } else {
              Toast({
                type: 4,
                content: "An error occured.",
              });
            }
          },
        });
      }
    });
  };

  const filteredReports = utilizationReports.filter((row) =>
    row.title?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const onChangeSearch = async (event) => {
    event.persist();

    if (changeSearchTimeoutID) {
      clearTimeout(changeSearchTimeoutID);
    }

    changeSearchTimeoutID = await setTimeout(() => {
      const eventValue = event.target.value;
      setSearchQuery(eventValue.trim());
    }, 1000);
  };

  const handleDownloadClick = async (xlsxLinks, attachments, title) => {
    const files = attachments ? [...xlsxLinks, ...attachments] : xlsxLinks;

    const zip = new JSZip();

    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      const parsedUrl = new URL(file);
      const rawfilePath = parsedUrl.pathname.slice(1);
      const fileName = parsedUrl.pathname.split("/").pop();
      const path = rawfilePath.split("/")[0];

      const decodedFileName = decodeURIComponent(fileName);

      const filePath = `${path}/${decodedFileName}`;

      try {
        // eslint-disable-next-line no-await-in-loop
        const data = await downloadS3File(filePath);
        zip.file(decodedFileName, data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error downloading ${decodedFileName}:`, error);
      }
    }

    const content = await zip.generateAsync({ type: "blob" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(content);
    link.download = `${title}.zip`;
    link.click();
  };

  const isImage = (type) =>
    ["jpg", "jpeg", "png", "heic", "gif"].includes(type);

  const isVideo = (type) =>
    ["mp4", "mpeg", "webm", "ogg", "avi"].includes(type);

  const renderAttachment = (row) => {
    if (row.attachments && row.attachments.length > 0) {
      const file = row.attachments[0];

      if (isImage(file.split(".").pop().toLowerCase())) {
        return (
          <CardImg
            src={file}
            alt="Event"
            style={{ width: "100%", height: "250px" }}
            onClick={() => {
              toggleCarousel();
              setImages(row.attachments);
            }}
          />
        );
      }

      if (isVideo(file.split(".").pop().toLowerCase())) {
        return (
          /* eslint-disable jsx-a11y/media-has-caption */
          <video
            style={{ width: "100%", height: "250px" }}
            controls
            onClick={() => {
              toggleCarousel();
              setImages(row.attachments);
            }}
          >
            <source
              src={`${file}?v=1`}
              type="video/mp4"
            />
          </video>
        );
      }
    }

    // Fallback image
    return (
      <CardImg
        src={noImgAvailable}
        alt="Event"
        style={{ width: "100%", height: "250px" }}
      />
    );
  };

  useEffect(() => {
    //  fetch all agencies & congressmen
    fetchCongressmen();
    fetchAgencies();
  }, []);

  useEffect(() => {
    if (selectedPrincipal) {
      if (PRINCIPAL_IDS.includes(selectedPrincipal.id)) {
        setDisplayOthersFilter(true);
        fetchOthers(selectedPrincipal.id);
      } else {
        setDisplayOthersFilter(false);
      }
    }
  }, [selectedPrincipal]);

  useEffect(() => {
    if (selectedAgency?.value !== undefined) {
      // reset selected project on agency change
      if (isAgencyChanged) setSelectedProject(null);
      fetchProjects();
    }
  }, [selectedAgency]);

  useEffect(() => {
    if (
      (selectedPrincipal && (selectedProject || selectedAgency?.id === 0)) ||
      selectedOthers
    ) {
      fetchDataTable();
    }

    if (
      userDetails.type === "Agency" &&
      selectedProject &&
      !selectedPrincipal
    ) {
      fetchDataTable();
    }
  }, [selectedPrincipal, selectedProject, selectedOthers, selectedAgency]);

  return (
    <MainContainer>
      <Container>
        <BreadCrumbSection>
          <BreadCrumbComponent
            links={[
              {
                name: "Home",
                redirect: "/home",
              },
              {
                name: "Utilization Report",
                isActive: true,
              },
            ]}
          />
        </BreadCrumbSection>

        <HeaderTextSection>
          <h2 className="text-danger">{"Utilization Report"}</h2>
          {hasPermission("utilization-create") && (
            <Link
              to="/utilization/add"
              state={{ action: "add" }}
            >
              <AddButton>
                <Plus className="mr-25" />
                <p className="mb-0">{"Add Utilization"}</p>
              </AddButton>
            </Link>
          )}
        </HeaderTextSection>

        <Row className="pt-4 mt-5 d-flex align-items-center select-container">
          <Col
            lg="9"
            className="d-flex align-items-center justify-content-end"
          >
            {(userDetails.type === "Director" ||
              userDetails.type === "Editor") && (
              <div className="settings-icon">
                <Settings
                  className="settings-hover"
                  onClick={() => {
                    toggleModal();
                  }}
                />
              </div>
            )}
          </Col>
          <Col
            lg="3"
            className="d-flex align-items-center justify-content-end d-none d-lg-flex"
          >
            {/* searchbar here */}
            <div className="position-relative util-search-bar">
              <SearchBarComponent onChangeSearch={onChangeSearch} />
            </div>
          </Col>
        </Row>

        <Row className="p-0 mt-3 d-flex align-items-center">
          <p>{"Select Filter"} </p>
          <Col
            lg="3"
            className="my-2"
          >
            <CustomSelect
              className="w-100"
              placeholder="Principal"
              components={{ DropdownIndicator }}
              classNamePrefix="select"
              styles={addProjectDropdownStyles}
              options={congressmenOptions}
              value={selectedPrincipal}
              onChange={(option) => {
                setSelectedPrincipal(option);
              }}
              isDisabled={disablePrincipal}
            />
          </Col>
          {displayOthersFilter && (
            <Col
              lg="3"
              className="my-2"
            >
              <CustomSelect
                className="w-100"
                placeholder="Others"
                components={{ DropdownIndicator }}
                classNamePrefix="select"
                styles={addProjectDropdownStyles}
                options={othersOptions}
                value={selectedOthers}
                onChange={(option) => setSelectedOthers(option)}
              />
            </Col>
          )}
          <Col
            lg="3"
            className="my-2"
          >
            <div className="agency-container">
              <CustomSelect
                placeholder="Agency"
                styles={addProjectDropdownStyles}
                components={{ DropdownIndicator }}
                classNamePrefix="select"
                options={agencyOptions}
                value={selectedAgency}
                onChange={(option) => {
                  setSelectedAgency(option);
                  setIsAgencyChanged(true);
                  if (option.id === 0) {
                    setIsAllAgency(true);
                    setSelectedProject(null);
                  } else setIsAllAgency(false);
                }}
                // eslint-disable-next-line consistent-return
                filterOption={(option, searchValue) => {
                  if (
                    option.label
                      .toLowerCase()
                      .includes(searchValue?.toLowerCase()) ||
                    option?.data?.acronym
                      ?.toLowerCase()
                      ?.includes(searchValue?.toLowerCase())
                  ) {
                    return option;
                  }
                }}
              />
            </div>
          </Col>

          <Col
            lg="3"
            className="my-2"
          >
            <CustomSelect
              className="w-100"
              placeholder="Program"
              components={{ DropdownIndicator }}
              classNamePrefix="select"
              styles={addProjectDropdownStyles}
              options={projectOptions}
              value={selectedProject}
              onChange={(option) => {
                setSelectedProject(option);
                setIsAgencyChanged(false);
              }}
              isDisabled={
                selectedAgency === null ||
                selectedAgency?.value === 14 ||
                isAllAgency
              }
            />
          </Col>
        </Row>
        <hr />
        <Row className="p-0 mt-3 d-flex align-items-center utilization-status">
          <Col
            lg="3"
            className="my-2"
          >
            <Card className="util-card">
              <Row className="align-items-center">
                <Col xs="auto">
                  <div className="util-card-icon">
                    <CreditCard />
                  </div>
                </Col>
                <Col>
                  <CardBody>
                    <CardTitle
                      tag="h6"
                      className="mb-2"
                    >
                      {"Total Allocation"}
                    </CardTitle>
                    <CardText className="font-weight-bold">
                      {getFormattedNumber(totalAllocation || "0.00")}
                    </CardText>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col
            lg="3"
            className="my-2"
          >
            <Card className="util-card">
              <Row className="align-items-center">
                <Col xs="auto">
                  <div className="util-card-icon">
                    <Star />
                  </div>
                </Col>
                <Col>
                  <CardBody>
                    <CardTitle
                      tag="h6"
                      className="mb-2"
                    >
                      {"Total Liquidation"}
                    </CardTitle>
                    <CardText className="font-weight-bold">
                      {getFormattedNumber(totalLiquidation || "0.00")}
                    </CardText>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col
            lg="3"
            className="my-2"
          >
            <Card className="util-card">
              <Row className="align-items-center">
                <Col xs="auto">
                  <div className="util-card-icon">
                    <Briefcase />
                  </div>
                </Col>
                <Col>
                  <CardBody>
                    <CardTitle
                      tag="h6"
                      className="mb-2"
                    >
                      {"Unliquidated Balance"}
                    </CardTitle>
                    <CardText className="font-weight-bold">
                      {getFormattedNumber(unliquidatedBalance || "0.00")}
                    </CardText>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col
            lg="3"
            className="my-2"
          >
            <Card className="util-card">
              <Row className="align-items-center">
                <Col xs="auto">
                  <div className="util-card-icon">
                    <Hash />
                  </div>
                </Col>
                <Col>
                  <CardBody>
                    <CardTitle
                      tag="h6"
                      className="mb-2"
                    >
                      {"No. of Reports"}
                    </CardTitle>
                    <CardText className="font-weight-bold">
                      {noOfReports}
                    </CardText>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <div>
          {filteredReports?.map((row) => {
            return (
              <Card
                key={row.id}
                className="mb-3 no-border"
              >
                <Row className="align-items-center">
                  <Col md="3">{renderAttachment(row)}</Col>
                  <Col md="9">
                    <CardBody>
                      <CardTitle tag="h3">{row.title}</CardTitle>
                      <Row>
                        <Col md="5">
                          <CardText>
                            <strong>{"Venue:"}</strong> {row.venue}
                          </CardText>
                          <CardText>
                            <strong>{"Date:"}</strong>{" "}
                            {getFormattedDateString(
                              row.dateImplemented,
                              "MMM-dd-yyy"
                            )}
                          </CardText>
                          <CardText>
                            <strong>{"No. of Beneficiaries:"}</strong>{" "}
                            {row.numberOfBeneficiary}
                          </CardText>
                          <CardText>
                            <strong>{"Principal:"}</strong>{" "}
                            {row.Congressman?.fullName}
                          </CardText>
                        </Col>
                        <Col md="5">
                          <CardText>
                            <strong>{"Cost of Beneficiaries:"}</strong>{" "}
                            {getFormattedNumber(
                              row.costPerBeneficiary || "0.00"
                            )}
                          </CardText>
                          <CardText>
                            <strong>{"Total Cost:"}</strong>{" "}
                            {getFormattedNumber(row.totalCost || "0.00")}
                          </CardText>
                          <CardText>
                            <strong>{"Others:"}</strong> {row.partners}
                          </CardText>
                        </Col>
                        <Col md="2">
                          <div className="actions">
                            <div>
                              <Download
                                size={16}
                                color="#4252C9"
                                className="mr-05"
                                onClick={() => {
                                  handleDownloadClick(
                                    row.xlsxLinks,
                                    row.attachments,
                                    row.title
                                  );
                                }}
                              />
                              {hasPermission("utilization-create") && (
                                <Edit2
                                  size={16}
                                  id={`edit-row--${row.id}`}
                                  color="#4252C9"
                                  className="mr-05"
                                  onClick={() => {
                                    navigate("/utilization/edit", {
                                      state: {
                                        row,
                                        action: "edit",
                                      },
                                    });
                                  }}
                                />
                              )}
                              {hasPermission("utilization-create") && (
                                <Trash2
                                  size={16}
                                  color="#4252C9"
                                  className="mr-05"
                                  onClick={() => handleDeleteRow(row)}
                                  id={`delete-row--${row.id}`}
                                />
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Col>
                </Row>
                <hr />
              </Card>
            );
          })}
        </div>
      </Container>

      <ImageVideoSlider
        images={images}
        isOpen={showOverlay}
        setIsOpen={setShowOverlay}
      />
      {(userDetails.type === "Director" || userDetails.type === "Editor") && (
        <SettingsModal
          isOpen={isModalShown}
          handleClose={toggleModal}
          agency={agencyOptions}
          handleFetchTable={fetchDataTable}
        />
      )}
    </MainContainer>
  );
};

export default UtilizationView;
