import BreadCrumbComponent from "components/customs/breadCrumb";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Col, Row } from "reactstrap";
import {
  getFormattedContactNumber,
  getFormattedOptions,
  Toast,
} from "utility/helpers";
import {
  fetchAllAgencies,
  fetchAllAgencyProjects,
} from "services/services.projects";
import { AddAccount } from "services/services.accounts";
import ImgContainer from "components/common/img";
import defaultUserImage from "assets/img/default_user.png";
import AgencyForm from "components/forms/Agency";
import { uploadFile } from "services/services.general";

import "../congressman/styles.scss";

const AgencyDetails = () => {
  const location = useLocation();
  const agency = location.state?.agency || null;

  const [selectedAgencyError, setSelectedAgencyError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [selectedProgramError, setSelectedProgramError] = useState("");
  const [programOptions, setProgramOptions] = React.useState([]);

  const [formFields, setFormFields] = useState({
    userId: "",
    lastname: "",
    firstname: "",
    middleInitial: "",
    email: "",
    mobile: "",
    agency: "",
    cos: "",
    cosNumber: "",
    password: "",
    passwordConfirmation: "",
    photo: "",
    photoName: "",
    cosFirstName: "",
    cosLastName: "",
    programs: [],
  });

  const navigate = useNavigate();

  const validate = () => {
    if (!selectedAgency) {
      setSelectedAgencyError("Required");

      return "Required agency";
    }
    setSelectedAgencyError("");

    if (formFields.photo === "") {
      return "Photo required.";
    }

    if (formFields.programs.length === 0) {
      setSelectedProgramError("Required");
    }
    setSelectedProgramError("");

    if (
      formFields.cosNumber?.length > 2 &&
      !/^(639)\d{9}$/.test(formFields?.cosNumber)
    ) {
      return "Invalid cos contact number.";
    }

    return true;
  };

  const setData = () => {
    const option = agencies.find((__agency) => __agency.name === agency.agency);

    if (option) setSelectedAgency(option);

    setIsLoading(false);
  };

  const setAgencyDetails = () => {
    setFormFields({
      ...formFields,
      userId: agency.userId,
      lastname: agency.lastName,
      firstname: agency.firstName,
      middleInitial: agency.middleName,
      mobile: getFormattedContactNumber(agency.phoneNumber) || "",
      agency: agency.agency,
      email: agency.email,
      cos: agency.cosName || "",
      cosNumber: getFormattedContactNumber(agency.cosContact) || "",
      photo: agency.photo,
      cosFirstName: agency.cosFirstName,
      cosLastName: agency.cosLastName || agency.cosName,
    });

    if (agency.photo) {
      const imgContainer = document.querySelector(".agency-img");
      imgContainer.src = agency.photo;
    }
  };

  const sendData = async (formData) => {
    await AddAccount({
      url: !agency ? "/auth/signup" : "/auth/update-user",
      method: agency ? "PUT" : "POST",
      formData,
      callback: ({ isSuccess, msg }) => {
        if (isSuccess) {
          setIsLoading(false);

          navigate("/directory", {
            state: { view: "agency" },
          });

          Toast({
            type: 1,
            content: msg,
          });
        } else {
          setIsLoading(false);

          Toast({
            type: 4,
            content: msg,
          });
        }
      },
    });
  };

  const uploadImage = async () => {
    let fileName = "";

    await uploadFile({
      file: formFields.photo,
      fileName: formFields.photoName,
      callback: (data) => {
        if (data) fileName = data;
      },
    });

    return fileName;
  };

  const handleOnSumbmit = async () => {
    const isValid = await validate();

    if (typeof isValid === "boolean") {
      const getSelectedPrograms = () => {
        let program = [];

        if (formFields.programs.some((_program) => _program.value === "all")) {
          program = programOptions
            .filter((_program) => _program.value !== "all")
            .map((_program) => _program.value);
        } else {
          program = formFields.programs.map((_program) => _program.value);
        }

        return program;
      };

      const formData = {
        username: formFields.email,
        middleName: formFields.middleInitial,
        firstName: formFields.firstname,
        lastName: formFields.lastname,
        phoneNumber: formFields.mobile?.slice(2),
        agency: selectedAgency.value,
        accountType: 2, // hard-coded value "Agency"
        cosFirstName: formFields.cosFirstName,
        cosLastName: formFields.cosLastName,
        cosContact: formFields.cosNumber?.slice(2),
        programIds: getSelectedPrograms(),
      };

      if (agency) formData.userId = formFields.userId;
      if (!agency) formData.password = formFields.password;

      setIsLoading(true);

      if (typeof formFields.photo !== "string") {
        const fileName = await uploadImage();
        formData.photo = fileName;
      }

      await sendData(formData);
    } else {
      Toast({
        type: 4,
        content: isValid,
      });
    }
  };

  const fetchData = async () => {
    await fetchAllAgencies({
      callback: (data) => {
        const newData = getFormattedOptions("name", data);

        setAgencies(newData);
      },
    });
    setIsLoading(false);
  };

  const handleFileUpload = (evt) => {
    const file = evt.target.files[0];
    const reader = new FileReader();
    const fileTye = file.type.split("/")[0];

    if (fileTye === "image") {
      reader.onloadend = () => {
        const imgContainer = document.querySelector(".agency-img");

        const fileName = agency
          ? `${
              agency.userId
            }-${formFields.lastname.toLocaleLowerCase()}-${Date.now()}`
          : `${formFields.lastname.toLocaleLowerCase()}-${Date.now()}`;

        setFormFields({
          ...formFields,
          photo: file,
          photoName: fileName,
        });

        imgContainer.src = reader.result;
      };

      reader.readAsDataURL(file);
    } else {
      Toast({
        type: 4,
        content: "File uploaded must be an image.",
      });
    }
  };

  const fetchPrograms = () => {
    fetchAllAgencyProjects({
      url: `/agency/${selectedAgency?.value}`,
      callback: (data) => {
        let options = data?.Projects;

        if (options.length !== 0) {
          options = getFormattedOptions("name", options);

          options.unshift({
            label: "All Programs",
            value: "all",
          });
        }

        setProgramOptions(options);
      },
    });
  };

  const setDefaultSelectedPrograms = () => {
    setFormFields({
      ...formFields,
      programs: [
        {
          label: "All Programs",
          value: "all",
        },
      ],
    });
  };

  useEffect(() => {
    if (
      formFields.programs.length > 1 &&
      formFields.programs?.some((project) => project.value === "all")
    ) {
      setDefaultSelectedPrograms();
    }

    if (
      programOptions.length !== 0 &&
      formFields.programs.length !== 0 &&
      programOptions.length - 1 === formFields.programs.length
    ) {
      setDefaultSelectedPrograms();
    }
  }, [formFields.programs]);

  useEffect(() => {
    if (agency && programOptions.length !== 0) {
      const programs = agency?.programIds;

      const options = programOptions.filter((op) => programs.includes(op.id));

      setFormFields({
        ...formFields,
        programs: options,
      });
    }
  }, [programOptions, agency]);

  useEffect(() => {
    setIsLoading(true);
    //  fetch all agency
    fetchData();
  }, []);

  useEffect(() => {
    if (agency !== null && agencies.length) {
      setAgencyDetails();
      setData();
    }
  }, [agency, agencies]);

  useEffect(() => {
    if (selectedAgency) {
      fetchPrograms();
    }
  }, [selectedAgency]);

  return (
    <div className="add-project-container directory">
      <Col className="header">
        <Row className="d-flex justify-content-end w-100 m-0">
          <Col
            xs="6"
            className="d-flex mt-3 justify-content-end align-items-center custom-breadcrumb--comtainer"
          >
            <BreadCrumbComponent
              links={[
                {
                  name: "Home",
                  redirect: "/home",
                },
                {
                  name: "Directory",
                  redirect: "/directory",
                },
                {
                  name: !agency ? "Add Agency" : "Agency Details",
                  isActive: true,
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="header-actions h-75 d-flex m-0 mt-4 justify-content-between align-items-end">
          <h2 className="text-danger">
            {!agency ? "Add Agency" : "Agency Details"}
          </h2>
        </Row>
        <div className="form-section">
          <Row>
            <Col
              xs="12"
              sm="6"
              lg="4"
              className="col--img-container"
            >
              <div className="img-container">
                <ImgContainer
                  alt="agency-img"
                  className="agency-img"
                  src={defaultUserImage}
                  width="196px"
                  height="196px"
                />

                <label
                  htmlFor="file-upload"
                  className="upload-btn"
                >
                  {"Upload Photo"}
                  <input
                    id="file-upload"
                    className="file-upload"
                    type="file"
                    onChange={handleFileUpload}
                  />
                </label>
              </div>
            </Col>
            <Col
              xs="12"
              sm="6"
              lg="8"
            >
              <AgencyForm
                handleOnSumbmit={handleOnSumbmit}
                formFields={formFields}
                setFormFields={setFormFields}
                agency={agency}
                agencies={agencies}
                selectedAgency={selectedAgency}
                setSelectedAgency={setSelectedAgency}
                selectedAgencyError={selectedAgencyError}
                isLoading={isLoading}
                selectedProgramError={selectedProgramError}
                programOptions={programOptions}
              />
            </Col>
          </Row>
        </div>
      </Col>
    </div>
  );
};

export default AgencyDetails;
