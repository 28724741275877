const APP_URL = process.env.REACT_APP_BASE_URL;
const REFRESH_KEY = process.env.REACT_APP_REFRESH_KEY;
const SESSION_KEY = process.env.REACT_APP_SESSION_KEY;
const ID_KEY = process.env.REACT_APP_ID_KEY;
const USER_KEY = "USER_DETAILS";
const TINGOG_URL = process.env.REACT_APP_TINGOG_URL;
const TINGOG_API_KEY = process.env.REACT_APP_TINGOG_API_KEY;
const AWS_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_ID;
const AWS_ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
const DEBUG = process.env.REACT_APP_DEBUG_MODE;
const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;

const getAccessToken = () => {
  return localStorage.getItem(SESSION_KEY);
};

const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_KEY);
};

const getIdToken = () => {
  return localStorage.getItem(ID_KEY);
};

const setAccessToken = (token, remove = false) => {
  if (token) {
    localStorage.setItem(SESSION_KEY, token);
  }

  if (remove) {
    localStorage.removeItem(SESSION_KEY);
  }
};

const setIdToken = (token, remove = false) => {
  if (token) {
    localStorage.setItem(ID_KEY, token);
  }

  if (remove) {
    localStorage.removeItem(ID_KEY);
  }
};

const setRefreshToken = (token, remove = false) => {
  if (token) {
    localStorage.setItem(REFRESH_KEY, token);
  }

  if (remove) {
    localStorage.removeItem(REFRESH_KEY);
  }
};

const setUserDetails = (data, remove = false) => {
  if (data) {
    localStorage.setItem(USER_KEY, JSON.stringify(data));
  }

  if (remove) {
    localStorage.removeItem(USER_KEY);
  }
};

const setUserPermissions = (data, remove = false) => {
  if (data) {
    localStorage.setItem("USER_PERMISSIONS", JSON.stringify(data));
  }

  if (remove) {
    localStorage.removeItem("USER_PERMISSIONS");
  }
};

const getUserDetails = () => {
  return JSON.parse(localStorage.getItem(USER_KEY) || null);
};

const getUserPermissions = () => {
  return JSON.parse(localStorage.getItem("USER_PERMISSIONS") || null);
};

export {
  APP_URL,
  REFRESH_KEY,
  SESSION_KEY,
  ID_KEY,
  TINGOG_URL,
  TINGOG_API_KEY,
  AWS_ACCESS_KEY_ID,
  AWS_ACCESS_KEY,
  DEBUG,
  S3_BUCKET,
  getAccessToken,
  setAccessToken,
  setRefreshToken,
  getRefreshToken,
  setUserDetails,
  getUserDetails,
  setIdToken,
  getIdToken,
  setUserPermissions,
  getUserPermissions,
};
