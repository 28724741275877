import React from "react";
import * as Yup from "yup";
import styled from "styled-components";
import { ErrorMessage, Field, Form, Formik } from "formik";
import classNames from "classnames";
import { Button, Col, Input, Row, Spinner } from "reactstrap";
import CustomSelect from "components/customs/dropdown";
import { DropdownIndicator, accountCustomStyle } from "utility/constants";
import AsyncSelect from "react-select/async";
import { getFormattedContactNumber } from "utility/helpers";
import PasswordInput from "components/customs/password-input/PasswordInput";
import { useNavigate } from "react-router";
import FormGenertor from "components/customs/form-generator/input";
import { getUserDetails } from "config";

const StyledRed = styled.span`
  color: red;
`;
const RequiredIndicator = <StyledRed>{"*"}</StyledRed>;

const PasswordChangeInfo = styled.div`
  border-top: solid #80808080 1px;
`;

const AccountForm = ({
  action,
  selectedAccountType,
  handleOnSumbmit,
  formFields,
  accountTypeError,
  setSelectedAccountType,
  accountTypeOptions,
  setSelectedCongressman,
  handleCongressmanSearch,
  selectedCongressman,
  congressmanError,
  setFormFields,
  agencyOptions,
  selectedAgency,
  setSelectedAgency,
  agencyError,
  isLoading,
  agenciesError,
  agenciesOptions,
  programsError,
  programOptions,
  agencyPrograms,
}) => {
  const userDetails = getUserDetails();

  const navigate = useNavigate();

  const isAgencySelectionShown =
    (action !== "view" && selectedAccountType?.value === 2) ||
    userDetails?.type === "Agency";

  const validSchema = Yup.object().shape({
    lastname: Yup.string().when("user", {
      is: () => selectedAccountType?.value !== 6,
      then: () => Yup.string().required("Required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    firstname: Yup.string().when("user", {
      is: () => selectedAccountType?.value !== 6,
      then: () => Yup.string().required("Required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    email: Yup.string().email("Invalid").required("Required"),
    mobile: Yup.string()
      .min(12, "At least 12 digits")
      .max(12, "Cannot be more than 12 digits")
      .matches(/^(639)\d{9}$/, "Invalid format")
      .required("Required"),
    password: Yup.string()
      .matches(/(?=.*?[A-Z])/, "At least one Uppercase")
      .matches(/(?=.*?[a-z])/, "At least one Lowercase")
      .matches(/(?=.*?[0-9])/, "At least one Digit")
      .matches(/(?=.*?[#?!@$%^&*-])/, "At least one Special Character")
      .matches(/.{8,}/, "At least 8 characters")
      .when("user", {
        is: () => action === "add",
        then: () => Yup.string().required("Required"),
        otherwise: () => Yup.string().notRequired(),
      }),
  });

  const handleAgenciesSelect = (options) => {
    const newOptions = options?.map((op) => ({
      id: op.id,
      value: op.id,
      label: op.acronym,
      acronym: op.acronym,
    }));

    setFormFields({
      ...formFields,
      agencies: newOptions,
    });
  };

  const handleProgramsSelectOnChange = (options) => {
    const newOptions =
      options?.map((op) => ({
        id: op.value,
        value: op.value,
        label: op.label,
      })) || [];

    setFormFields({
      ...formFields,
      programs: newOptions,
    });
  };

  const setDefaultSelectedPrograms = () => {
    setFormFields({
      ...formFields,
      programs: [
        {
          label: "All Programs",
          value: "all",
        },
      ],
    });
  };

  const setDefaultSelectedAgencies = () => {
    setFormFields({
      ...formFields,
      agencies: [
        {
          label: "All Agencies",
          value: "all",
          acronym: "All Agencies",
        },
      ],
    });
  };

  React.useEffect(() => {
    if (selectedAgency) {
      setDefaultSelectedPrograms();
    }
  }, [selectedAgency]);

  React.useEffect(() => {
    if (
      formFields.programs.length > 1 &&
      formFields.programs?.some((project) => project.value === "all")
    ) {
      setDefaultSelectedPrograms();
    }

    if (
      agencyPrograms.length !== 0 &&
      formFields.programs.length !== 0 &&
      agencyPrograms.length === formFields.programs.length
    ) {
      setDefaultSelectedPrograms();
    }
  }, [formFields.programs]);

  React.useEffect(() => {
    if (
      formFields.agencies.length > 1 &&
      formFields.agencies?.some((project) => project.value === "all")
    ) {
      setDefaultSelectedAgencies();
    }

    if (
      agencyOptions.length !== 0 &&
      formFields.agencies.length !== 0 &&
      formFields.agencies.length === agencyOptions.length
    ) {
      setDefaultSelectedAgencies();
    }
  }, [formFields.agencies]);

  return (
    <Formik
      validationSchema={validSchema}
      onSubmit={handleOnSumbmit}
      initialValues={formFields}
      enableReinitialize
    >
      {({ handleChange, values }) => (
        <Form id="add-account-form">
          <Row className="m-0 p-0">
            <Row className="p-0 m-0 mt-3 d-flex justify-content-between align-items-center">
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">
                  {"Account Type"}
                  {RequiredIndicator}
                </h5>
                <p>{"Provide your Agency Type"}</p>
              </Col>
              <Col
                xs="12"
                md="6"
              >
                <CustomSelect
                  name="agency_type"
                  placeholder="Account Type"
                  styles={accountCustomStyle}
                  components={{ DropdownIndicator }}
                  classNamePrefix="select is-invalid"
                  onChange={(option) => {
                    setSelectedAccountType(option);
                  }}
                  options={accountTypeOptions}
                  value={selectedAccountType}
                  isDisabled={action !== "add"}
                />
                <small className="field-error text-danger">
                  {accountTypeError}
                </small>
              </Col>
            </Row>

            {selectedAccountType &&
            selectedAccountType?.value !== 4 &&
            selectedAccountType?.value !== 2 ? (
              <Row className="p-0 m-0 mt-3 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">
                    {"Agencies"}
                    {RequiredIndicator}
                  </h5>
                  <p>{"Select agencies"}</p>
                </Col>
                <Col
                  xs="12"
                  md="6"
                >
                  <CustomSelect
                    placeholder=""
                    name="agencies"
                    isMulti
                    styles={accountCustomStyle}
                    components={{ DropdownIndicator }}
                    classNamePrefix="select"
                    options={
                      formFields.agencies?.some(
                        (project) => project.value === "all"
                      )
                        ? []
                        : agenciesOptions
                    }
                    value={formFields?.agencies}
                    onChange={handleAgenciesSelect}
                    getOptionLabel={(option) => {
                      return option?.acronym;
                    }}
                    // eslint-disable-next-line consistent-return
                    filterOption={(option, searchValue) => {
                      if (
                        option?.label
                          ?.toLowerCase()
                          ?.includes(searchValue?.toLowerCase()) ||
                        option?.data?.acronym
                          ?.toLowerCase()
                          ?.includes(searchValue?.toLowerCase())
                      ) {
                        return option;
                      }
                    }}
                    isDisabled={action === "view"}
                  />
                  <small className="field-error text-danger">
                    {agenciesError}
                  </small>
                </Col>
              </Row>
            ) : null}

            {(selectedAccountType?.value === 7 ||
              selectedAccountType?.value === 6) && (
              <Row className="p-0 m-0 mt-3 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">
                    {"Congressman"}
                    {RequiredIndicator}
                  </h5>
                  <p>{"Search for an existing Congressman"}</p>
                </Col>
                <Col
                  xs="12"
                  md="6"
                >
                  <AsyncSelect
                    placeholder=""
                    styles={accountCustomStyle}
                    components={{ DropdownIndicator }}
                    classNamePrefix="select"
                    onChange={(option) => {
                      setSelectedCongressman(option);

                      if (selectedAccountType?.value === 7) {
                        setFormFields({
                          ...formFields,
                          firstname:
                            option.cos.name || option.cos.firstName || "",
                          lastname: option.cos.lastName || "",
                          mobile: getFormattedContactNumber(option.cos.contact),
                        });
                      }

                      if (selectedAccountType?.value === 6) {
                        setFormFields({
                          ...formFields,
                          lastname: option.lastName,
                          firstname: option.firstName,
                          mobile: getFormattedContactNumber(option.contact),
                        });
                      }
                    }}
                    loadOptions={handleCongressmanSearch}
                    value={selectedCongressman}
                    isDisabled={action !== "add"}
                  />
                  <small className="field-error text-danger">
                    {congressmanError}
                  </small>
                </Col>
              </Row>
            )}

            {selectedAccountType?.value !== 6 || action !== "add" ? (
              <>
                <Field name="lastname">
                  {({ form: { touched, errors } }) => (
                    <Row className="p-0 m-0 mt-3 d-flex justify-content-between align-items-center">
                      <Col
                        xs="12"
                        md="6"
                      >
                        <h5 className="font-weight-bold">
                          {"Last Name"}
                          {RequiredIndicator}
                        </h5>
                        <p>{"Provide your Last Name"}</p>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Input
                          type="text"
                          name="lastname"
                          autoComplete="off"
                          className={classNames("form-control", {
                            "is-invalid": errors?.lastname && touched?.lastname,
                          })}
                          value={values.lastname}
                          onChange={(evt) => {
                            handleChange(evt);

                            setFormFields({
                              ...formFields,
                              lastname: evt.target.value || "",
                            });
                          }}
                        />

                        <ErrorMessage name="lastname">
                          {(msg) => (
                            <small className="field-error text-danger">
                              {msg}
                            </small>
                          )}
                        </ErrorMessage>
                      </Col>
                    </Row>
                  )}
                </Field>

                <Field name="firstname">
                  {({ form: { touched, errors } }) => (
                    <Row className="p-0 m-0 mt-3 d-flex justify-content-between align-items-center">
                      <Col
                        xs="12"
                        md="6"
                      >
                        <h5 className="font-weight-bold">
                          {"First Name"}
                          {RequiredIndicator}
                        </h5>
                        <p>{"Provide your First Name"}</p>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Input
                          type="text"
                          name="firstname"
                          autoComplete="off"
                          className={classNames("form-control", {
                            "is-invalid":
                              errors?.firstname && touched?.firstname,
                          })}
                          value={values.firstname}
                          onChange={(evt) => {
                            handleChange(evt);

                            setFormFields({
                              ...formFields,
                              firstname: evt.target.value || "",
                            });
                          }}
                        />
                        <ErrorMessage name="firstname">
                          {(msg) => (
                            <small className="field-error text-danger">
                              {msg}
                            </small>
                          )}
                        </ErrorMessage>
                      </Col>
                    </Row>
                  )}
                </Field>
              </>
            ) : null}

            <Field name="email">
              {({ form: { touched, errors } }) => (
                <Row className="p-0 m-0 mt-3 d-flex justify-content-between align-items-center">
                  <Col
                    xs="12"
                    md="6"
                  >
                    <h5 className="font-weight-bold">
                      {"Email Address"}
                      {RequiredIndicator}
                    </h5>
                    <p>{"Provide your Email Address"}</p>
                  </Col>
                  <Col
                    xs="12"
                    md="6"
                  >
                    <Input
                      type="text"
                      name="email"
                      className={classNames("form-control", {
                        "is-invalid": errors?.email && touched?.email,
                      })}
                      value={values.email}
                      onChange={(evt) => {
                        handleChange(evt);

                        setFormFields({
                          ...formFields,
                          email: evt.target.value || "",
                        });
                      }}
                      disabled={action === "edit"}
                      autoComplete="off"
                    />
                    <ErrorMessage name="email">
                      {(msg) => (
                        <small className="field-error text-danger">{msg}</small>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
              )}
            </Field>

            <Field name="mobile">
              {({ form: { touched, errors } }) => (
                <Row className="p-0 m-0 mt-3 d-flex justify-content-between align-items-center">
                  <Col
                    xs="12"
                    md="6"
                  >
                    <h5 className="font-weight-bold">
                      {"Mobile Number"}
                      {RequiredIndicator}
                    </h5>
                    <p>{"Provide your Mobile Number"}</p>
                  </Col>
                  <Col
                    xs="12"
                    md="6"
                  >
                    <FormGenertor
                      name="mobile"
                      type="mobile"
                      className={classNames("form-control", {
                        "is-invalid": errors?.mobile && touched?.mobile,
                      })}
                      value={values.mobile || 63}
                      onChange={(value) => {
                        handleChange(value);

                        setFormFields({
                          ...formFields,
                          mobile: value || "",
                        });
                      }}
                    />
                    <ErrorMessage name="mobile">
                      {(msg) => (
                        <small className="field-error text-danger">{msg}</small>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
              )}
            </Field>

            {isAgencySelectionShown ? (
              <>
                <Row className="p-0 m-0 mt-3 d-flex justify-content-between align-items-center">
                  <Col
                    xs="12"
                    md="6"
                  >
                    <h5 className="font-weight-bold">
                      {"Agency"}
                      {RequiredIndicator}
                    </h5>
                    <p>{"Provide the name of the Agency"}</p>
                  </Col>
                  <Col
                    xs="12"
                    md="6"
                  >
                    <CustomSelect
                      placeholder="Agency"
                      name="agency"
                      isDisabled={
                        userDetails?.type === "Agency" ||
                        selectedAccountType?.value !== 2
                      }
                      styles={accountCustomStyle}
                      components={{ DropdownIndicator }}
                      classNamePrefix="select"
                      options={agencyOptions}
                      value={selectedAgency}
                      maxMenuHeight="200px"
                      onChange={(option) => setSelectedAgency(option)}
                      // eslint-disable-next-line consistent-return
                      filterOption={(option, searchValue) => {
                        if (
                          option?.label
                            ?.toLowerCase()
                            ?.includes(searchValue?.toLowerCase()) ||
                          option?.data?.acronym
                            ?.toLowerCase()
                            ?.includes(searchValue?.toLowerCase())
                        ) {
                          return option;
                        }
                      }}
                    />
                    <small className="field-error text-danger">
                      {agencyError}
                    </small>
                  </Col>
                </Row>

                <Row className="p-0 m-0 mt-3 d-flex justify-content-between align-items-center">
                  <Col
                    xs="12"
                    md="6"
                  >
                    <h5 className="font-weight-bold">
                      {"Programs"}
                      {RequiredIndicator}
                    </h5>
                    <p>{"Select program/s under the Agency"}</p>
                  </Col>
                  <Col
                    xs="12"
                    md="6"
                  >
                    <CustomSelect
                      placeholder=""
                      name="programs"
                      isMulti
                      isDisabled={!selectedAgency}
                      styles={accountCustomStyle}
                      components={{ DropdownIndicator }}
                      classNamePrefix="select"
                      options={
                        formFields.programs?.some(
                          (project) => project.value === "all"
                        )
                          ? []
                          : programOptions
                      }
                      value={formFields?.programs}
                      onChange={handleProgramsSelectOnChange}
                    />

                    <small className="field-error text-danger">
                      {programsError}
                    </small>
                  </Col>
                </Row>
              </>
            ) : null}

            {action !== "view" && (
              <>
                {action === "edit" && (
                  <PasswordChangeInfo className="mt-3 pt-3 text-danger">
                    {
                      "Do not add password if you don't want to change user's password"
                    }
                  </PasswordChangeInfo>
                )}
                <Field name="password">
                  {({ form: { touched, errors } }) => (
                    <Row className="p-0 m-0 mt-3 d-flex justify-content-between align-items-center">
                      <Col
                        xs="12"
                        md="6"
                      >
                        <h5 className="font-weight-bold">
                          {"Password"}
                          {RequiredIndicator}
                        </h5>
                        <p>{"Type in your Password"}</p>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <PasswordInput
                          type="password"
                          name="password"
                          value={values.password}
                          className={classNames("form-control", {
                            "is-invalid": errors?.password && touched?.password,
                          })}
                          onChange={(evt) => {
                            handleChange(evt);

                            setFormFields({
                              ...formFields,
                              password: evt.target.value || "",
                            });
                          }}
                        />
                        <ErrorMessage name="password">
                          {(msg) => (
                            <small className="field-error text-danger">
                              {msg}
                            </small>
                          )}
                        </ErrorMessage>
                      </Col>
                    </Row>
                  )}
                </Field>

                <Field name="passwordConfirmation">
                  {({ form: { touched, errors } }) => (
                    <Row className="p-0 m-0 mt-3 d-flex justify-content-between align-items-center">
                      <Col
                        xs="12"
                        md="6"
                      >
                        <h5 className="font-weight-bold">
                          {"Confirm Password"}
                          {RequiredIndicator}
                        </h5>
                        <p>{"Re-type your Password"}</p>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <PasswordInput
                          type="password"
                          name="passwordConfirmation"
                          value={values.passwordConfirmation}
                          className={classNames("form-control", {
                            "is-invalid":
                              errors?.passwordConfirmation &&
                              touched?.passwordConfirmation,
                          })}
                          onChange={(evt) => {
                            handleChange(evt);

                            setFormFields({
                              ...formFields,
                              passwordConfirmation: evt.target.value || "",
                            });
                          }}
                        />
                        <ErrorMessage name="passwordConfirmation">
                          {(msg) => (
                            <small className="field-error text-danger">
                              {msg}
                            </small>
                          )}
                        </ErrorMessage>
                      </Col>
                    </Row>
                  )}
                </Field>
              </>
            )}
          </Row>

          <Row className="m-0 mt-5 d-flex justify-content-end">
            {action !== "view" && (
              <Button
                className="btn-cancel mr-1"
                disabled={isLoading}
                onClick={() => navigate("/accounts")}
              >
                {"Back"}
              </Button>
            )}
            <Button
              type="submit"
              className="btn-save"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  animation="border"
                  color="light"
                  size="sm"
                />
              ) : (
                "Save"
              )}
            </Button>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default AccountForm;
