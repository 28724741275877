import React, { useEffect, useState } from "react";
import { Row, Col, Card, UncontrolledTooltip, Button } from "reactstrap";
import ImgContainer from "components/common/img";
import BreadCrumbComponent from "components/customs/breadCrumb";
import SearchBarComponent from "components/customs/searchbarV2";
import CustomDataTable from "components/customs/table";
import CustomSelect from "components/customs/dropdown";
import { useLocation, useNavigate } from "react-router";
import {
  DropdownIndicator,
  customStyles,
  defaultTableOption,
} from "utility/constants";
import { Edit2, Plus, Trash2 } from "react-feather";
import {
  Toast,
  getFormattedContactNumber,
  getFormattedNumber,
  getFormattedOptions,
  hasPermission,
} from "utility/helpers";
import { fetchTable, fetchAllAgencies } from "services/services.projects";
import { fetchDistricts } from "services/services.congressman";
import { fetchPartylists } from "services/services.affiliation";
import userImage from "assets/img/user.png";
import { TINGOG_URL } from "config";
import { fetchATCList, fetchAtcOptions } from "services/services.directory";
import SeeMoreOrLess from "components/customs/text-see-more-or-see-less";
import useTextSeeMoreOrLess from "hooks/useTextSeeMoreOrLess";
import { genericDelete } from "services/services.general";
import Swal from "sweetalert2";

import "./styles.scss";

const selectorOptions = [
  {
    value: 1,
    label: "Congressmen",
  },
  {
    value: 2,
    label: "Agency",
  },
  {
    value: 3,
    label: "Regional Directors",
  },
  {
    value: 4,
    label: "Alagang Tingog Center",
  },
];

const agencyIds = [8, 11, 16, 28, 27];

const DirectoryList = () => {
  const [tableOption, setTableOption] = useState(defaultTableOption);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [columns, setColumns] = useState([]);
  const [partyList, setPartyList] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [agencyOptions, setAgencyOptions] = useState([]);
  const [atcBranchOptions, setAtcBranchOptions] = useState([]);
  const [selectedAtcBranch, setSelectedAtcBranch] = useState(null);
  const [atcOptions, setAtcOptions] = useState([]);
  const [atcProgramOptions, setAtcProgramOptions] = useState([]);
  const [selectedAtcProgram, setSelectedAtcProgram] = useState(null);

  const location = useLocation();

  const view = location.state?.view || null;
  const agencySelect = location.state?.agencySelect || null;

  const navigate = useNavigate();

  const { insertOrRemoveExpandedSeeMoreOrLessTexts, isTextExpanded } =
    useTextSeeMoreOrLess();

  let changeSearchTimeoutID;

  const onChangeSearch = (event) => {
    event.persist();

    if (changeSearchTimeoutID) {
      clearTimeout(changeSearchTimeoutID);
    }

    changeSearchTimeoutID = setTimeout(() => {
      const eventValue = event.target.value;

      setTableOption({
        ...tableOption,
        page: 1,
        searchValue: eventValue.trim(),
      });
    }, 1000);
  };

  const onChangePage = (page) => {
    setTableOption({
      ...tableOption,
      page,
      offset: tableOption.currentRowsPerPage * page - 10,
    });
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    setTableOption({
      ...tableOption,
      currentRowsPerPage,
      page: currentPage,
    });
  };

  const sortArray = (column, sortDirection) => {
    const { identifier } = column;
    let newData = [];

    newData = tableOption.data.sort((a, b) => {
      const nameA = a[identifier].toUpperCase();
      const nameB = b[identifier].toUpperCase();

      if (nameA < nameB) {
        return sortDirection === "asc" ? -1 : 1;
      }

      if (nameA > nameB) {
        return sortDirection === "asc" ? 1 : -1;
      }

      return 0;
    });

    setTableOption({
      ...tableOption,
      data: newData,
    });
  };

  const sortAtcs = (column, sortDirection) => {
    const { identifier } = column;
    let newData = [];

    const numberIdentifier = [
      "allocations",
      "expenditures",
      "remaining_balance",
    ];

    if (numberIdentifier.includes(identifier)) {
      if (sortDirection === "asc") {
        newData = tableOption.data.sort(
          (a, b) => a[identifier] - b[identifier]
        );
      } else {
        newData = tableOption.data.sort(
          (a, b) => b[identifier] - a[identifier]
        );
      }

      setTableOption({
        ...tableOption,
        data: newData,
      });

      return;
    }

    if (identifier === "scope") {
      newData = tableOption.data.sort((a, b) => {
        const municipalitiesA = a.user_account[0].scope_municipalities
          .map((m) => m.name)
          .join(",");

        const municipalitiesB = b.user_account[0].scope_municipalities
          .map((m) => m.name)
          .join(",");

        if (municipalitiesA < municipalitiesB) {
          return sortDirection === "asc" ? -1 : 1;
        }

        if (municipalitiesA > municipalitiesB) {
          return sortDirection === "asc" ? 1 : -1;
        }

        return 0;
      });

      setTableOption({
        ...tableOption,
        data: newData,
      });

      return;
    }

    if (identifier === "province") {
      newData = tableOption.data.sort((a, b) => {
        const provinceA = a.user_account[0].scope_province.name.toUpperCase();

        const provinceB = b.user_account[0].scope_province.name.toUpperCase();

        if (provinceA < provinceB) {
          return sortDirection === "asc" ? -1 : 1;
        }

        if (provinceA > provinceB) {
          return sortDirection === "asc" ? 1 : -1;
        }

        return 0;
      });

      setTableOption({
        ...tableOption,
        data: newData,
      });

      return;
    }

    if (identifier === "partner") {
      newData = tableOption.data.sort((a, b) => {
        const municipalityA = a.municipalities.map((m) => m.name).join(",");

        const municipalityB = b.municipalities.map((m) => m.name).join(",");

        if (municipalityA < municipalityB) {
          return sortDirection === "asc" ? -1 : 1;
        }

        if (municipalityA > municipalityB) {
          return sortDirection === "asc" ? 1 : -1;
        }

        return 0;
      });

      setTableOption({
        ...tableOption,
        data: newData,
      });

      return;
    }

    newData = tableOption.data.sort((a, b) => {
      const nameA = a[identifier]?.toUpperCase();
      const nameB = b[identifier]?.toUpperCase();

      if (nameA < nameB) {
        return sortDirection === "asc" ? -1 : 1;
      }

      if (nameA > nameB) {
        return sortDirection === "asc" ? 1 : -1;
      }

      return 0;
    });

    setTableOption({
      ...tableOption,
      data: newData,
    });
  };

  // eslint-disable-next-line consistent-return
  const onSort = (column, sortDirection) => {
    if (selectedOption?.value === 2) return sortArray(column, sortDirection);

    if (selectedOption?.value === 4) return sortAtcs(column, sortDirection);

    setTableOption({
      ...tableOption,
      sort: {
        field: column.identifier,
        direction: sortDirection,
      },
    });
  };

  const fetchData = async () => {
    await fetchDistricts({
      callback: (data) => {
        setDistricts(data);
      },
    });

    await fetchPartylists({
      callback: (data) => {
        setPartyList(data);
      },
    });

    await fetchAllAgencies({
      callback: (data) => {
        const options = getFormattedOptions("acronym", data);

        setAgencyOptions(options);
      },
    });

    await fetchAtcOptions({
      callback: (data) => {
        const options = data
          ?.filter((atc) => atc.principal)
          ?.map((atc) => ({
            ...atc,
            label: `${atc?.last_name}, ${atc?.first_name}`,
            value: atc?.id,
          }));
        setAtcBranchOptions(options);

        const principals = getFormattedOptions("name", data);

        const uniqueNamesSet = new Set();
        const newPrincipals = [];

        principals.filter((item) => {
          if (!uniqueNamesSet.has(item.name)) {
            newPrincipals.push(item);
            uniqueNamesSet.add(item.name);

            return true;
          }

          return false;
        });

        setAtcOptions(newPrincipals);
      },
    });
  };

  const fetchAtcTable = async () => {
    await fetchATCList({
      url: `${TINGOG_URL}/integration/directory`,
      params: {
        limit: tableOption.currentRowsPerPage,
        offset: tableOption.offset,
        // eslint-disable-next-line camelcase
        search_value: tableOption.searchValue,
        // eslint-disable-next-line camelcase
        atc_branch: selectedAtcBranch?.principal?.atc_name,
        agency: selectedAgency?.id,
        program: selectedAtcProgram?.id,
      },
      callback: (data) => {
        setTableOption({
          ...tableOption,
          isLoading: false,
          data: data.results,
          totalRows: data.count,
        });
      },
    });
  };

  // eslint-disable-next-line consistent-return
  const fetchDataTable = async (agency) => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });

    if (selectedOption.value === 4) return fetchAtcTable();

    let url = "";

    switch (selectedOption.value) {
      case 1:
        url = "/congressman";
        break;

      case 3:
        url = `/regional-director/?agency=${agency || selectedAgency?.value}`;
        break;

      default:
        url = "/auth/list-users";
        break;
    }

    await fetchTable({
      url,
      params: {
        limit: tableOption.currentRowsPerPage,
        offset: tableOption.offset,
        search: tableOption.searchValue,
        column: tableOption.sort.field,
        order: tableOption.sort.direction,
        type: selectedOption.value === 1 ? "page" : null,
      },
      callback: (data) => {
        let rows = [];
        let totalRows = 0;

        switch (selectedOption.value) {
          case 1:
            rows = data.rows;
            totalRows = data.count;
            break;

          case 2:
            rows = data.data.filter((__data) => __data.type === "Agency");
            totalRows = rows.length;
            break;

          case 3:
            rows = data.rows;
            totalRows = data.count;
            break;

          default:
            break;
        }

        setTableOption({
          ...tableOption,
          data: rows,
          totalRows,
          isLoading: false,
        });
      },
    });
  };

  const handleDeleteAction = (row) => {
    Swal.fire({
      title: "Warning",
      // eslint-disable-next-line max-len
      text: `Are you sure you want to delete ${row?.firstName} ${row?.lastName}?`,
      icon: "warning",
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setTableOption({
          ...tableOption,
          isLoading: true,
        });

        let url = "";

        const { value } = selectedOption;

        switch (value) {
          case 3:
            url = `/regional-director/${row.id}`;
            break;

          case 2:
            url = `/auth/delete-user/${row.username}`;
            break;

          case 1:
            url = `/congressman/${row.id}`;
            break;

          default:
            break;
        }

        genericDelete({
          url,
          callback: ({ isSuccess }) => {
            setTableOption({
              ...tableOption,
              isLoading: false,
            });

            if (isSuccess) {
              Toast({
                type: 1,
                content: "Successfull deleted action.",
              });
              fetchDataTable(selectedAgency?.value);
            } else {
              Toast({
                type: 4,
                content: "An error occured.",
              });
            }
          },
        });
      }
    });
  };

  const agencyColumn = [
    {
      name: "",
      selector: null,
      sortable: false,
      id: "highlight",
      width: "5px",
      cell: () => null,
    },
    {
      name: "",
      selector: ({ photo }) => photo,
      grow: 0,
      minWidth: "80px",
      sortable: false,
      style: { paddingTop: "0.5rem", paddingBottom: "0.5rem" },
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ photo }) => (
        <div className="photo-frame">
          <ImgContainer
            alt="cong-image"
            src={photo || userImage}
            width="50px"
          />
        </div>
      ),
    },
    {
      name: "Agency",
      selector: ({ agency }) => agency,
      identifier: "agency",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ agency }) => <p className="mb-0 wrap-text">{agency}</p>,
    },
    {
      name: "Name",
      selector: ({ firstName }) => firstName,
      identifier: "firstName",
      grow: 3,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ firstName, middleName, lastName }) => (
        <p className="mb-0 wrap-text">{`${firstName} ${
          middleName || ""
        } ${lastName}`}</p>
      ),
    },
    {
      name: "Contact Info",
      selector: ({ phoneNumber }) => phoneNumber,
      identifier: "phoneNumber",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ phoneNumber }) => (
        <p className="mb-0 wrap-text">
          {getFormattedContactNumber(phoneNumber)}
        </p>
      ),
    },
    {
      name: "Cos",
      selector: ({ cosName }) => cosName,
      identifier: "cosName",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ cosFirstName, cosLastName, cosName }) => (
        <p className="mb-0 wrap-text">
          {cosFirstName && cosLastName
            ? `${cosFirstName} ${cosLastName}`
            : cosName || ""}
        </p>
      ),
    },
    {
      name: "Contact Info",
      selector: ({ cosContact }) => cosContact,
      identifier: "cosContact",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ cosContact }) => (
        <p className="mb-0 wrap-text">
          {getFormattedContactNumber(cosContact)}
        </p>
      ),
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: false,
      width: "100px",
      style: { cursor: "pointer" },
      center: true,
      id: "action",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row, index) => (
        <>
          {hasPermission("agency-update") && (
            <>
              <Edit2
                size={16}
                id={`edit-row--${index}`}
                onClick={() => {
                  navigate("/agency/", {
                    state: { agency: row },
                  });
                }}
                className="mr-05"
                color="#4252C9"
              />
              <UncontrolledTooltip
                placement="auto"
                target={`edit-row--${index}`}
              >
                {"Edit"}
              </UncontrolledTooltip>
            </>
          )}

          {hasPermission("agency-delete") && (
            <>
              <Trash2
                size={16}
                id={`delete-row--${index}`}
                onClick={() => handleDeleteAction(row)}
                color="#4252C9"
              />
              <UncontrolledTooltip
                placement="right"
                target={`delete-row--${index}`}
              >
                {"Delete"}
              </UncontrolledTooltip>
            </>
          )}
        </>
      ),
    },
  ];

  const regionalDirectorsColumn = [
    {
      name: "",
      selector: null,
      sortable: false,
      id: "highlight",
      width: "5px",
      cell: () => null,
    },
    {
      name: "Region",
      selector: ({ region }) => region,
      identifier: "region",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ region }) => <p className="mb-0 wrap-text">{region.name}</p>,
    },
    {
      name: "Name",
      selector: ({ name }) => name,
      identifier: "name",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ name, firstName, lastName }) => (
        <p className="mb-0 wrap-text">
          {firstName && lastName ? `${firstName} ${lastName}` : name || ""}
        </p>
      ),
    },
    {
      name: "Contact Info",
      selector: ({ contact }) => contact,
      identifier: "contact",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ contact }) => (
        <p className="mb-0 wrap-text">{getFormattedContactNumber(contact)}</p>
      ),
    },
    {
      name: "Cos",
      selector: ({ cosName }) => cosName,
      identifier: "cosName",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ cosFirstName, cosLastName, cosName }) => (
        <p className="mb-0 wrap-text">
          {cosFirstName && cosLastName
            ? `${cosFirstName} ${cosLastName}`
            : cosName || ""}
        </p>
      ),
    },
    {
      name: "Contact Info",
      selector: ({ cosContact }) => cosContact,
      identifier: "cosContact",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ cosContact }) => (
        <p className="mb-0 wrap-text">
          {getFormattedContactNumber(cosContact)}
        </p>
      ),
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: false,
      width: "100px",
      style: { cursor: "pointer" },
      center: true,
      id: "action",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row, index) => (
        <>
          {hasPermission("regional-director-update") && (
            <>
              <Edit2
                size={16}
                id={`edit-row--${index}`}
                onClick={() => {
                  navigate("/director", {
                    state: {
                      director: row,
                    },
                  });
                }}
                className="mr-05"
                color="#4252C9"
              />
              <UncontrolledTooltip
                placement="auto"
                target={`edit-row--${index}`}
              >
                {"Edit"}
              </UncontrolledTooltip>
            </>
          )}

          {hasPermission("regional-director-delete") && (
            <>
              <Trash2
                size={16}
                id={`delete-row--${index}`}
                onClick={() => handleDeleteAction(row)}
                color="#4252C9"
              />
              <UncontrolledTooltip
                placement="right"
                target={`delete-row--${index}`}
              >
                {"Delete"}
              </UncontrolledTooltip>
            </>
          )}
        </>
      ),
    },
  ];

  const fetchAgencyPrograms = () => {
    fetchTable({
      url: `/agency/${selectedAgency.id}`,
      params: {},
      callback: (data) => {
        const { Projects } = data;

        if (Projects?.length) {
          const options = getFormattedOptions("name", Projects);

          if (options) {
            setAtcProgramOptions(options);
            setSelectedAtcProgram(options[0]);
          }
        } else {
          setAtcProgramOptions([]);
          setSelectedAtcProgram(null);
        }
      },
    });
  };

  const congColumn = [
    {
      name: "",
      selector: null,
      sortable: false,
      id: "highlight",
      width: "5px",
      cell: () => null,
    },
    {
      name: "",
      selector: ({ photo }) => photo,
      grow: 0,
      minWidth: "80px",
      sortable: false,
      style: { paddingTop: "0.5rem", paddingBottom: "0.5rem" },
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ photo }) => (
        <div className="photo-frame">
          <ImgContainer
            alt="cong-image"
            src={photo}
            width="50px"
          />
        </div>
      ),
    },
    {
      name: "Name",
      selector: (row) => row?.fullName,
      identifier: "fullName",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ fullName }) => <p className="mb-0 wrap-text">{fullName}</p>,
    },
    {
      name: "District",
      selector: (row) => row?.DistrictId,
      identifier: "DistrictId",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ DistrictId }) => (
        <p className="mb-0 wrap-text">
          {districts.find((district) => district.id === DistrictId)?.name}
        </p>
      ),
    },
    {
      name: "Party",
      selector: ({ PartyId }) => PartyId,
      identifier: "PartyId",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ PartyId }) => (
        <p className="mb-0">
          {partyList.find((party) => party.id === PartyId)?.acronym}
        </p>
      ),
    },
    {
      name: "Contact Info",
      selector: ({ contact }) => contact,
      identifier: "contact",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ contact }) => (
        <p className="mb-0">{getFormattedContactNumber(contact)}</p>
      ),
    },
    {
      name: "COS",
      selector: ({ cosName }) => cosName,
      identifier: "cosName",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ cosName, cosFirstName, cosLastName }) => (
        <p className="mb-0 wrap-text">
          {cosFirstName && cosLastName
            ? `${cosFirstName} ${cosLastName}`
            : cosName || ""}
        </p>
      ),
    },
    {
      name: "Contact Info",
      selector: ({ cosContact }) => cosContact,
      identifier: "cosContact",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ cosContact }) => (
        <p className="mb-0">{getFormattedContactNumber(cosContact)}</p>
      ),
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: false,
      width: "100px",
      style: { cursor: "pointer" },
      center: true,
      id: "action",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row, index) => (
        <>
          {hasPermission("congressman-update") && (
            <>
              <Edit2
                size={16}
                id={`edit-row--${index}`}
                onClick={() => {
                  navigate("/cong", {
                    state: {
                      cong: row,
                    },
                  });
                }}
                className="mr-05"
                color="#4252C9"
              />
              <UncontrolledTooltip
                placement="auto"
                target={`edit-row--${index}`}
              >
                {"Edit"}
              </UncontrolledTooltip>
            </>
          )}

          {hasPermission("congressman-delete") && (
            <>
              <Trash2
                size={16}
                id={`delete-row--${index}`}
                onClick={() => handleDeleteAction(row)}
                color="#4252C9"
              />
              <UncontrolledTooltip
                placement="right"
                target={`delete-row--${index}`}
              >
                {"Delete"}
              </UncontrolledTooltip>
            </>
          )}
        </>
      ),
    },
  ];

  const atcColumn = [
    {
      name: "",
      selector: null,
      sortable: false,
      id: "highlight",
      width: "5px",
      cell: () => null,
    },
    {
      name: "Name",
      // eslint-disable-next-line camelcase
      selector: ({ principal }) => principal?.atc_name,
      identifier: "atc_name",
      grow: 2,
      minWidth: "150px",
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components, camelcase
      cell: ({ first_name, last_name }) => (
        // eslint-disable-next-line camelcase
        <p className="mb-0">{`${last_name}, ${first_name}`}</p>
      ),
    },
    {
      name: "Province",
      // eslint-disable-next-line camelcase
      selector: ({ scope_province }) => scope_province?.name,
      identifier: "province",
      grow: 2,
      minWidth: "140px",
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components, camelcase
      cell: ({ scope_province }) => (
        // eslint-disable-next-line camelcase
        <p className="mb-0">{scope_province?.name}</p>
      ),
    },
    {
      name: "Scope",
      // eslint-disable-next-line camelcase
      selector: ({ scope_municipalities }) => scope_municipalities,
      identifier: "scope",
      grow: 3,
      minWidth: "180px",
      sortable: false,
      // eslint-disable-next-line react/no-unstable-nested-components, camelcase
      cell: ({ scope_municipalities, id }) => (
        <p className="mb-0 w-100 align-items-center">
          <SeeMoreOrLess
            // eslint-disable-next-line camelcase
            text={scope_municipalities
              ?.map((municipality) => municipality?.name)
              ?.join(", ")}
            id={`scope-${id}`}
            isTextExpanded={isTextExpanded}
            insertOrRemoveExpandedSeeMoreOrLessTexts={
              insertOrRemoveExpandedSeeMoreOrLessTexts
            }
          />
        </p>
      ),
    },
    {
      name: "Principal",
      // eslint-disable-next-line camelcase
      selector: ({ principal }) => principal?.atc_principal,
      identifier: "atc_principal",
      grow: 3,
      minWidth: "180px",
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components, camelcase
      cell: ({ principal }) => (
        // eslint-disable-next-line camelcase
        <p className="mb-0">
          {principal?.atc_principal?.replace(/none|None|NONE/g, "")}
        </p>
      ),
    },
    {
      name: "Principal Contact Number",
      // eslint-disable-next-line camelcase
      selector: ({ principal }) => principal?.atc_contact,
      identifier: "atc_contact",
      grow: 2,
      minWidth: "150px",
      sortable: false,
      // eslint-disable-next-line react/no-unstable-nested-components, camelcase
      cell: ({ principal }) => (
        <p className="mb-0">
          {getFormattedContactNumber(principal?.atc_contact)}
        </p>
      ),
    },
    {
      name: "cos",
      // eslint-disable-next-line camelcase
      selector: ({ principal }) => principal?.chief_of_staff,
      identifier: "chief_of_staff",
      grow: 2,
      minWidth: "150px",
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components, camelcase
      cell: ({ principal }) => (
        <p className="mb-0">
          {principal?.chief_of_staff?.replace(/none|None|NONE/g, "")}
        </p>
      ),
    },
    {
      name: "Cos Contact",
      // eslint-disable-next-line camelcase
      selector: ({ principal }) => principal?.chief_of_staff_contact,
      identifier: "chief_of_staff_contact",
      grow: 2,
      minWidth: "150px",
      sortable: false,
      // eslint-disable-next-line react/no-unstable-nested-components, camelcase
      cell: ({ principal }) => (
        <p className="mb-0">
          {getFormattedContactNumber(principal?.chief_of_staff_contact)}
        </p>
      ),
    },
    {
      name: "Allocations",
      selector: ({ allocations }) => allocations,
      identifier: "allocations",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ allocations }) => (
        <p className="mb-0">
          {!allocations || allocations === 0
            ? "PHP 0.00"
            : getFormattedNumber(allocations)}
        </p>
      ),
    },
    {
      name: "Expenditures",
      selector: ({ expenditures }) => expenditures,
      identifier: "expenditures",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ expenditures }) => (
        <p className="mb-0">{getFormattedNumber(expenditures)}</p>
      ),
    },
    {
      name: "Remaining Balance",
      // eslint-disable-next-line camelcase
      selector: ({ remaining_balance }) => remaining_balance,
      identifier: "remaining_balance",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components, camelcase
      cell: ({ remaining_balance }) => (
        <p className="mb-0">{getFormattedNumber(remaining_balance)}</p>
      ),
    },
  ];

  useEffect(() => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });
    fetchData();

    if (view) {
      const option = selectorOptions.find((options) =>
        options.label.toLocaleLowerCase().includes(view)
      );

      setSelectedOption(option);
    } else {
      setSelectedOption(selectorOptions[0]);
    }
  }, []);

  useEffect(() => {
    if (
      selectedOption !== null &&
      partyList.length &&
      districts.length &&
      agencyOptions.length &&
      atcOptions.length
    ) {
      if (selectedOption.value === 1) {
        setColumns(congColumn);
      } else if (selectedOption.value === 2) {
        setColumns(agencyColumn);
      } else if (selectedOption.value === 3) {
        setColumns(regionalDirectorsColumn);

        if (agencySelect) {
          setSelectedAgency(
            agencyOptions.find((_agency) => _agency.id === agencySelect)
          );
        } else {
          setSelectedAgency(agencyOptions[0]);
        }
      } else if (selectedOption.value === 4) {
        // set default selected agency to DOH
        const option = agencyOptions?.find((agency) => agency.id === 8);

        setSelectedAgency(option);
        setSelectedAtcBranch(null);
        setColumns(atcColumn);
      }

      fetchDataTable();
    }
  }, [selectedOption, districts, partyList, agencyOptions, atcOptions]);

  useEffect(() => {
    if (selectedOption) {
      fetchDataTable();
    }
  }, [
    selectedAtcProgram,
    selectedAtcBranch,
    selectedAgency,
    tableOption.currentRowsPerPage,
    tableOption.offset,
    tableOption.searchValue,
    tableOption.sort.field,
    tableOption.sort.direction,
  ]);

  useEffect(() => {
    if (selectedAgency) {
      fetchAgencyPrograms();
    }
  }, [selectedAgency]);

  return (
    <div className="directory-container">
      <Row className="d-flex justify-content-end mt-5">
        <Col
          xs="auto"
          className="custom-breadcrumb--comtainer"
        >
          <BreadCrumbComponent
            links={[
              {
                name: "Home",
                redirect: "/home",
              },
              {
                name: "Directory",
                isActive: true,
              },
              {
                name: selectedOption?.label,
                isActive: true,
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="p-0 mb-4 d-flex align-items-center justify-content-between select-container">
        <Col
          xs="12"
          md={
            // eslint-disable-next-line no-nested-ternary
            selectedOption?.value === 4
              ? "12"
              : selectedOption?.value === 3
              ? "7"
              : "4"
          }
          lg={
            // eslint-disable-next-line no-nested-ternary
            selectedOption?.value === 4
              ? "8"
              : selectedOption?.value === 3
              ? "7"
              : "4"
          }
          xl={
            // eslint-disable-next-line no-nested-ternary
            selectedOption?.value === 4
              ? "8"
              : selectedOption?.value === 3
              ? "5"
              : "3"
          }
        >
          <div className="select-container">
            <div className="select-parent">
              <CustomSelect
                isLoading={tableOption.isLoading}
                isDisabled={tableOption.isLoading}
                onChange={(option) => setSelectedOption(option)}
                value={selectedOption}
                options={selectorOptions}
                styles={customStyles}
                components={{ DropdownIndicator }}
                classNamePrefix="select"
              />
            </div>

            {selectedOption?.value === 4 && (
              <div className="select-parent">
                <CustomSelect
                  isLoading={tableOption.isLoading}
                  isDisabled={tableOption.isLoading}
                  onChange={(option) => setSelectedAtcBranch(option)}
                  placeholder="ATC Name"
                  value={selectedAtcBranch}
                  options={atcBranchOptions}
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                  classNamePrefix="select"
                />
              </div>
            )}

            {(selectedOption?.value === 3 || selectedOption?.value === 4) && (
              <div className="select-parent">
                <CustomSelect
                  isLoading={tableOption.isLoading}
                  isDisabled={tableOption.isLoading}
                  onChange={(option) => setSelectedAgency(option)}
                  placeholder="Agency"
                  value={selectedAgency}
                  options={
                    selectedOption?.value === 4
                      ? agencyOptions.filter((agency) =>
                          agencyIds.includes(agency.id)
                        )
                      : agencyOptions
                  }
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                  classNamePrefix="select"
                />
              </div>
            )}

            {selectedOption?.value === 4 && (
              <div className="select-parent">
                <CustomSelect
                  isLoading={tableOption.isLoading}
                  isDisabled={tableOption.isLoading || !selectedAgency}
                  onChange={(option) => setSelectedAtcProgram(option)}
                  placeholder="Program"
                  value={selectedAtcProgram}
                  options={atcProgramOptions}
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                  classNamePrefix="select"
                />
              </div>
            )}
          </div>
        </Col>

        <Col
          lg={selectedOption?.value === 4 ? "3" : "4"}
          className="d-flex align-items-center justify-content-end d-none d-lg-flex"
        >
          {/* searchbar here */}
          <div className="position-relative">
            <SearchBarComponent
              onChangeSearch={onChangeSearch}
              isDisabled={tableOption?.isLoading}
            />
          </div>
        </Col>
      </Row>
      <Row className="p-0 mb-4 ">
        <Col
          xs="12"
          className="d-flex justify-content-end"
        >
          {selectedOption?.value !== 4 && (
            <Button
              className="add-btn"
              disabled={tableOption.isLoading}
              onClick={() => {
                if (selectedOption.value === 1) {
                  navigate("/cong");
                } else if (selectedOption.value === 2) {
                  navigate("/agency");
                } else if (selectedOption.value === 3) {
                  navigate("/director", {
                    state: {
                      agency: selectedAgency.value,
                    },
                  });
                }
              }}
            >
              <Plus />
              <p className="mb-0 ml-25 font-weight-bold">{"Add New Entry"}</p>
            </Button>
          )}
        </Col>
      </Row>
      <Row className="p-0 d-flex row-section">
        <Col>
          {/* table here */}
          <Card className="mb-5">
            <CustomDataTable
              columns={columns}
              data={tableOption.data}
              noHeader
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onSort={onSort}
              paginationPerPage={tableOption.currentRowsPerPage}
              paginationServer
              paginationTotalRows={tableOption.totalRows}
              progressPending={tableOption.isLoading}
              sortServer
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DirectoryList;
