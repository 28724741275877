import React, { useState, useEffect } from "react";
import { Button, Col, Input, Row, Spinner } from "reactstrap";
import BreadCrumbComponent from "components/customs/breadCrumb";
import CustomSelect from "components/customs/dropdown";
import { Plus } from "react-feather";
import {
  Toast,
  getFormattedContactNumber,
  getFormattedOptions,
  hasPermission,
} from "utility/helpers";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import Swal from "sweetalert2";
import {
  assignProjectCongressman,
  fetchAllAgencies,
  fetchAllAgencyProjects,
} from "services/services.projects";
import "react-datepicker/dist/react-datepicker.css";
import {
  fetchAllRegions,
  fetchCongressmanDetails,
} from "services/services.congressman";
import { congressmanSearch } from "services/services.navbar";
import {
  DropdownIndicator,
  addProjectDropdownStyles,
  canEditRoles,
  congRoles,
} from "utility/constants";
import { fetchAtcOptions } from "services/services.directory";
import { useNavigate } from "react-router";
import { getUserDetails } from "config";
import api from "services/api";
import { genericGet } from "services/services.general";
import { useDispatch } from "react-redux";
import AddProgramForm from "./Form";
import { AddRowSection } from "../styled";
import { fetchPendingProjects } from "../../../redux/actions/projects";

const defaultProjectRecipients = {
  recipientName: "",
  budgetAllocation: "",
  allocationType: 1,
};

const AddProjectComponent = () => {
  const dispatch = useDispatch();

  const [agencyOptions, setAgencOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [regionOptions, setRegionOptions] = React.useState([]);
  const [action, setAction] = useState("");

  const [projectRecipients, setProjectRecipients] = useState([
    defaultProjectRecipients,
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setFormIsLoading] = useState(false);
  const [atcOptions, setAtcOptions] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isFormResetted, setIsFormResetted] = useState(false);

  const [projectDetailsFormData, setProjectDetailsFormData] = useState({
    agency: "",
    project: "",
    recipients: [],
    projectName: "",
  });

  const userDetails = getUserDetails();

  const cong = userDetails?.congressman || null;

  const navigate = useNavigate();

  const isRoleCongressman = congRoles.includes(userDetails.type);

  const fetchAgencies = async () => {
    await fetchAllAgencies({
      callback: (data) => {
        const newData = getFormattedOptions("name", data);
        setAgencOptions(newData);
      },
    });
  };

  const fetchProjects = async () => {
    await fetchAllAgencyProjects({
      url: `/agency/${selectedAgency?.value}`,
      callback: (data) => {
        let projects = data?.Projects;

        if (selectedAgency?.value === 14) {
          projects = data?.Projects.filter(
            (project) => project.name === "Infrastructure"
          );
        }

        const options = getFormattedOptions("name", projects);
        if (selectedAgency?.value === 14) setSelectedProject(options[0]);
        setProjectOptions(options);
      },
    });
  };

  const handleDeleteRow = (e, index) => {
    e.preventDefault();
    const newProjectRecipients = [...projectRecipients];
    newProjectRecipients.splice(index, 1);
    setProjectRecipients(newProjectRecipients);
  };

  const handleAddRow = () => {
    const newProjectRecipients = [...projectRecipients];
    newProjectRecipients.push(defaultProjectRecipients);
    setProjectRecipients(newProjectRecipients);
  };

  const handleSearchInputOnChange = (value, index) => {
    const newProjectRecipients = [...projectRecipients];

    const region = regionOptions.find((reg) => reg.value === value.region.id);

    const newValue =
      value.region.id !== 18
        ? {
            ...value,
            region,
          }
        : {
            ...value,
            region: null,
          };

    newProjectRecipients[index] = {
      ...newProjectRecipients[index],
      recipientName: newValue,
      allocationType: 1,
    };

    setProjectRecipients(newProjectRecipients);
  };

  const resetForm = () => {
    if (!isRoleCongressman) setProjectRecipients([defaultProjectRecipients]);
    setSelectedProject(null);
    setSelectedAgency(null);
    setUploadedFiles([]);

    setProjectDetailsFormData({
      agency: "",
      project: "",
      recipients: [],
      projectName: "",
    });

    setIsFormResetted(true);
  };

  const isFormValid = () => {
    let isDisabled = false;

    if (selectedAgency === null) {
      isDisabled = true;
    }

    if (action === "add" && projectDetailsFormData.projectName === "") {
      isDisabled = true;
    }

    if (action === "" && !selectedProject && selectedAgency?.value !== 14) {
      isDisabled = true;
    }

    projectRecipients?.forEach((recipient) => {
      if (
        !recipient?.recipientName?.value ||
        recipient?.budgetAllocation === ""
      ) {
        isDisabled = true;
      }

      if (!recipient.recipientName?.region) {
        isDisabled = true;
      }
    });

    return isDisabled;
  };

  const AssignCong = async (congressmenData) => {
    await assignProjectCongressman({
      formData: congressmenData,
      callback: ({ isSuccess, msg }) => {
        setIsLoading(false);

        if (isSuccess) {
          if (userDetails.type === "Director") dispatch(fetchPendingProjects());

          const text = msg.replace(/\n/g, "<br>");

          Swal.fire({
            html: text,
            icon: "success",
            customClass: text.includes("<br>")
              ? { container: "sms-notif" }
              : null,
          }).then((result) => {
            if (result.isConfirmed && isRoleCongressman) {
              navigate(`/representatives/${cong.region.id}/cong/${cong.id}`);
            }
          });
          resetForm();
        } else {
          Swal.fire({
            title: "Error",
            text: msg,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      },
    });
  };

  const getFormData = (projectId) => {
    const files = uploadedFiles?.map((file) => file.location) || [];

    const getInstitution = (i) => {
      let institution = null;

      if (i.allocationType !== 2 && selectedAgency?.value !== 8) {
        if (
          selectedAgency?.value === 27 &&
          (selectedProject?.value === 10 || selectedProject?.value === 15)
        ) {
          institution = i.institution.value;
        }

        if (selectedAgency?.value === 14) {
          institution = i.institution;
        }

        if (selectedAgency?.value === 3 && selectedProject?.value === 14) {
          institution = i.institution;
        }
      } else if (selectedAgency?.value === 8) {
        institution = i.institution.value;
      } else if (
        selectedAgency?.value === 3 &&
        i.allocationType === 3 &&
        selectedProject?.value === 14
      ) {
        institution = i.institution;
      }

      return institution;
    };

    const data = {
      projectId,
      congressmen: projectRecipients.map((item) => ({
        id: item.recipientName.value,
        budget: item.budgetAllocation,
        agencyId: item.recipientName.region.value,
        allocationTypeId: item.allocationType,
        allocationName: item.allocationType === 2 ? item?.allocationName : null,
        allocationContactName:
          item.allocationType !== 1 ? item?.allocationContactName : null,
        allocationContactNumber:
          item.allocationType !== 1
            ? item?.allocationContactNumber?.slice(2)
            : null,
        allocationReferenceKey:
          item.allocationType === 2 ? item?.allocationReferenceKey : null,
        allocation:
          item.allocationType === 2
            ? {
                atcId: item?.allocation?.atcId,
                principalId: item?.allocation?.principalId,
                principal: {
                  manager: {
                    firstName: item?.allocation?.managerFirstName,
                    lastName: item?.allocation?.managerLastName,
                    contact: item?.allocation?.managerContact,
                  },
                },
              }
            : null,
        documents: files ? { supporting: files } : null,
        provinceId:
          item?.Province?.value !== "All Provinces"
            ? item?.Province?.value
            : null,
        municipalityId:
          item?.Municipality?.value !== "All Municipalities"
            ? item?.Municipality?.value
            : null,
        regionId: item.recipientName?.region?.value || null,
        institution: getInstitution(item),
        slots: item?.slots ? item?.slots : null,
        justification: item?.justification || null,
        ownership:
          selectedAgency?.value === 8 &&
          typeof item?.institution?.value === "string"
            ? item?.facilityType?.value
            : null,
        fundSource:
          (selectedAgency?.value === 14 && item?.allocationType !== 2) ||
          (selectedAgency?.value === 3 &&
            selectedProject?.value === 14 &&
            item?.allocationType !== 2)
            ? item?.fundSource?.value || null
            : null,
        implementingOffice:
          selectedAgency?.value === 14 && item.allocationType !== 2
            ? item?.implementingOffice
            : null,
        otherInfo:
          selectedAgency?.value === 3 &&
          selectedProject?.value === 14 &&
          (item?.allocationType === 1 || item?.allocationType === 3)
            ? item?.otherInfo || null
            : null,
        editorNotes: item?.notes?.map((note) => note?.value?.trim()).join(";"),
      })),
    };

    return data;
  };

  const AddProject = async () => {
    try {
      const projData = {
        agencyId: selectedAgency.id,
        name: projectDetailsFormData.projectName,
      };
      let projectId = null;

      await new Promise((resolve, reject) => {
        api({
          Method: "POST",
          Url: "/project",
          Data: projData,
          ResponseSuccessCallback: (response) => {
            if (response && response?.request?.status === 201) {
              projectId = response?.data?.data?.id;
              resolve(response);
            } else {
              reject(new Error("Failed to create program"));
            }
          },
          ResponseErrorCallback: (error) => {
            reject(error);
          },
        });
      });

      if (!projectId) {
        throw new Error("Failed to get program ID");
      }

      const data = getFormData(projectId);

      await AssignCong(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      setIsLoading(false);

      Swal.fire({
        title: "Error",
        text: "There was an error while assigning congressmen to the program",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const validate = () => {
    return new Promise((resolve, reject) => {
      const promises = [];

      projectRecipients?.forEach((recipient) => {
        const checkPromise = new Promise((resolveCheck) => {
          if (
            recipient.allocationType === 3 &&
            recipient.allocationContactNumber &&
            !/^(639)\d{9}$/.test(recipient.allocationContactNumber)
          ) {
            resolveCheck("Invalid contact number");
          }

          if (
            recipient.allocationType === 2 &&
            recipient.allocationContactNumber &&
            !/^(639)\d{9}$/.test(recipient.allocationContactNumber)
          ) {
            resolveCheck("Invalid atc partner contact");
          }

          if (
            recipient.allocationType === 3 &&
            (!recipient.allocationContactName ||
              !recipient.allocationContactNumber)
          ) {
            resolveCheck("Recipient Contact and Contact Number are required.");
          }

          if (recipient.allocationType === 2 && !recipient.allocationName) {
            resolveCheck("ATC is required");
          }

          if (
            selectedAgency?.value === 27 &&
            recipient.allocationType !== 2 &&
            (selectedProject?.value === 10 || selectedProject?.value === 15) &&
            !recipient?.institution
          ) {
            resolveCheck("Name of TVI is required");
          }

          if (
            selectedAgency?.value === 14 &&
            recipient.allocationType !== 2 &&
            uploadedFiles.length === 0
          ) {
            resolveCheck("Supporting Documents cannot be empty");
          }

          if (
            selectedAgency?.value === 14 &&
            recipient.allocationType !== 2 &&
            recipient?.justification === ""
          ) {
            resolveCheck("Justification is required");
          }

          if (
            selectedAgency?.value === 14 &&
            recipient.allocationType !== 2 &&
            canEditRoles.includes(userDetails.type) &&
            recipient?.fundSource === ""
          ) {
            resolveCheck("Fund Source is required");
          }

          if (
            selectedAgency?.value === 14 &&
            recipient.allocationType !== 2 &&
            recipient?.implementingOffice === ""
          ) {
            resolveCheck("Implementing Office is required");
          }

          if (
            selectedAgency?.value === 14 &&
            recipient.allocationType !== 2 &&
            !recipient?.institution
          ) {
            resolveCheck("Project Title is required");
          }

          if (
            selectedAgency?.value === 8 &&
            recipient.allocationType !== 2 &&
            !recipient.institution
          ) {
            resolveCheck("Facility is required");
          }

          if (
            selectedAgency?.value === 3 &&
            (recipient.allocationType === 1 ||
              recipient.allocationType === 3) &&
            selectedProject?.value === 14 &&
            !recipient.institution
          ) {
            resolveCheck("Project Title is required");
          }

          if (
            selectedAgency?.value === 3 &&
            (recipient.allocationType === 1 ||
              recipient.allocationType === 3) &&
            selectedProject?.value === 14 &&
            !recipient.otherInfo
          ) {
            resolveCheck("LGSF Tracking Code is required");
          }

          resolveCheck(true);
        });

        promises.push(checkPromise);
      });

      Promise.all(promises)
        .then((results) => {
          const errors = results.filter((result) => result !== true);

          if (errors.length > 0) {
            resolve(errors.join("\n"));
          } else {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleSaveForm = async () => {
    const isValid = await validate();

    if (typeof isValid === "boolean") {
      setIsLoading(true);

      if (!selectedProject) {
        AddProject();
      } else {
        const data = getFormData(selectedProject.value);

        AssignCong(data);
      }
    } else {
      Toast({
        type: 4,
        content: isValid,
      });
    }
  };

  const handleCongressmanSearch = _.debounce((inputValue, callback) => {
    congressmanSearch({
      inputValue,
      callback,
    });
  }, 1000);

  // eslint-disable-next-line consistent-return
  const setFirstEntry = () => {
    return new Promise((resolve, reject) => {
      const { congressman } = userDetails;

      fetchCongressmanDetails({
        params: congressman.id,
        callback: (data) => {
          const value = {
            recipientName: {
              value: data.id,
              label: data.fullName,
              region:
                data.District.Region.id !== 18
                  ? {
                      id: data.District.Region.id,
                      value: data.District.Region.id,
                      label: data.District.Region.acronym,
                      acronym: data.District.Region.acronym,
                    }
                  : null,
            },
          };
          resolve(value);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  };

  const fetchAllATC = async () => {
    await fetchAtcOptions({
      callback: (data) => {
        const options = data
          ?.filter((atc) => atc.principal)
          ?.map((atc) => ({
            ...atc,
            label: `${atc?.last_name}, ${atc?.first_name}`,
            value: atc?.id,
          }));

        setAtcOptions(options);

        let value = {
          allocationName: options[0]?.label,
          allocationContactNumber: getFormattedContactNumber(
            options[0]?.principal?.atc_partner_contact
          ),
          allocationContactName: options[0]?.principal?.atc_partner_last_name
            ? // eslint-disable-next-line max-len
              `${options[0]?.principal?.atc_partner_first_name} ${options[0]?.principal?.atc_partner_last_name}`
            : options[0]?.principal?.atc_partner_first_name,
          allocationReferenceKey: options[0]?.principal?.id,
          allocation: {
            atcId: options[0]?.id,
            principalId: options[0]?.principal?.id,
          },
        };

        const newProjectRecipients = [...projectRecipients];

        newProjectRecipients[0] = {
          ...newProjectRecipients[0],
          ...value,
        };

        setProjectRecipients(newProjectRecipients);

        if (isRoleCongressman) {
          setFirstEntry().then((res) => {
            value = {
              ...value,
              ...res,
            };

            newProjectRecipients[0] = {
              ...newProjectRecipients[0],
              ...value,
            };

            setProjectRecipients(newProjectRecipients);
          });
        }
      },
    });
  };

  const handleUploadedFiles = (link) => {
    setUploadedFiles((prevUploadedFiles) => {
      const fileIndex = prevUploadedFiles.findIndex(
        (file) => file.name === link.name
      );

      if (fileIndex !== -1) {
        const updatedFiles = [...prevUploadedFiles];
        updatedFiles[fileIndex] = link;

        return updatedFiles;
      }

      if (prevUploadedFiles.length < 5) {
        return [...prevUploadedFiles, link];
      }

      Toast({
        type: 4,
        content: "Exceeded file upload limit of 5",
      });

      return prevUploadedFiles;
    });
  };

  const handleDeleteFile = (file) => {
    const newUploadedFiles = uploadedFiles.filter(
      (files) => files.name !== file
    );
    setUploadedFiles(newUploadedFiles);
  };

  const handleDeleteAllFiles = () => {
    setUploadedFiles([]);
  };

  const fetchRegion = async () => {
    await fetchAllRegions({
      callback: (data) => {
        if (data.length) {
          const options = data
            .filter((region) => region.name !== "Partylist")
            .map(({ acronym, id, omegaId }) => ({
              omegaId,
              label: acronym,
              value: id,
            }));

          setRegionOptions(options);
        }
      },
    });
  };

  const fetchUtilization = () => {
    setFormIsLoading(true);

    genericGet({
      url: `/project/${selectedProject?.value}/${cong?.id}/utilization-needed`,
      callback: (res) => {
        if (res) {
          Swal.fire({
            title: "Info",
            // eslint-disable-next-line max-len
            text: `You cannot request for additional budget for ${selectedProject?.label} at this time. Please provide utilization reports for previous allocations via Utilization Reports tab. Thank you!`,
            icon: "info",
            confirmButtonText: "OK",
          });
        } else {
          setFormIsLoading(false);
        }
      },
    });
  };

  useEffect(() => {
    //  fetch all agencies
    fetchAgencies();
    fetchAllATC();
    fetchRegion();
  }, []);

  useEffect(() => {
    if (selectedAgency) {
      // reset selected project on agency change
      setSelectedProject(null);
      setUploadedFiles([]);
      fetchProjects();
    }
  }, [selectedAgency]);

  useEffect(() => {
    if (selectedProject && isRoleCongressman) {
      fetchUtilization();
    }
  }, [selectedProject]);

  return (
    <div className="add-project-container">
      <Col className="header">
        <Row className="d-flex justify-content-end w-100 m-0">
          <Col
            xs="6"
            className="mr-2 mt-3 d-flex justify-content-end align-items-center custom-breadcrumb--comtainer"
          >
            <BreadCrumbComponent
              links={[
                {
                  name: "Home",
                  redirect: !isRoleCongressman
                    ? "/home"
                    : `/representatives/${cong?.region?.id}/cong/${cong?.id}`,
                },
                {
                  name: "Add Program",
                  isActive: true,
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="header-text h-75 d-flex m-0 align-items-end">
          <h2 className="text-danger">{"Projects"}</h2>
        </Row>
      </Col>
      <div className="main-container">
        {hasPermission("agency-view") && (
          <Row className="mt-5 m-0 d-flex justify-content-between">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">{"Agency"}</h5>
              <p>{"Provide the name of the agency"}</p>
            </Col>
            <Col
              xs="12"
              md="6"
            >
              <div className="agency-container">
                <CustomSelect
                  placeholder="Agency"
                  styles={addProjectDropdownStyles}
                  components={{ DropdownIndicator }}
                  classNamePrefix="select"
                  options={agencyOptions}
                  value={selectedAgency}
                  onChange={(option) => {
                    setSelectedAgency(option);
                  }}
                  // eslint-disable-next-line consistent-return
                  filterOption={(option, searchValue) => {
                    if (
                      option.label
                        .toLowerCase()
                        .includes(searchValue?.toLowerCase()) ||
                      option?.data?.acronym
                        ?.toLowerCase()
                        ?.includes(searchValue?.toLowerCase())
                    ) {
                      return option;
                    }
                  }}
                />

                <div className="empty-space" />
              </div>
            </Col>
          </Row>
        )}

        <Row className="mt-4 m-0 d-flex justify-content-between">
          <Col
            xs="12"
            md="6"
          >
            <h5 className="font-weight-bold">{"Program"}</h5>
            <p>{"Provide the name of the program"}</p>
          </Col>
          <Col
            xs="12"
            md="6"
          >
            <div className="add-project-section">
              {action === "" ? (
                <CustomSelect
                  className="w-100"
                  placeholder="Program"
                  components={{ DropdownIndicator }}
                  classNamePrefix="select"
                  styles={addProjectDropdownStyles}
                  options={projectOptions}
                  value={selectedProject}
                  onChange={(option) => setSelectedProject(option)}
                  isDisabled={
                    selectedAgency === null || selectedAgency?.value === 14
                  }
                />
              ) : (
                <Input
                  placeholder="Program"
                  className="input-date form-control"
                  onChange={(e) => {
                    const { value } = e.target;

                    setProjectDetailsFormData({
                      ...projectDetailsFormData,
                      projectName: value,
                    });
                  }}
                  value={projectDetailsFormData?.projectName}
                />
              )}

              {selectedAgency?.value === 14 || isRoleCongressman ? (
                <div className="add-proj-field" />
              ) : null}

              {action === "" &&
              !isRoleCongressman &&
              selectedAgency?.value !== 14 ? (
                <a
                  href="!#"
                  onClick={(e) => {
                    e.preventDefault();
                    setAction("add");
                    setSelectedProject(null);

                    if (!isRoleCongressman) {
                      setProjectRecipients([defaultProjectRecipients]);
                    }

                    setProjectDetailsFormData({
                      agency: "",
                      project: "",
                      recipients: [],
                      projectName: "",
                    });
                  }}
                  className="add-proj-field p-0"
                >
                  {"+ Add Program"}
                </a>
              ) : null}

              {action === "add" ? (
                <div className="cancel-app-proj-btn">
                  <a
                    href="!#"
                    onClick={(e) => {
                      e.preventDefault();
                      setAction("");
                      resetForm();
                    }}
                  >
                    <FontAwesomeIcon
                      size="xl"
                      color="#4252C9"
                      icon={faCircleXmark}
                    />
                  </a>
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
        {/*  eslint-disable-next-line no-nested-ternary */}
        {action === "add" || selectedProject || selectedAgency?.value === 14 ? (
          !isFormLoading ? (
            <>
              <hr />

              <div className="mt-4 m-0 d-flex justify-content-between flex-column house-member-section">
                {projectRecipients?.length &&
                  projectRecipients?.map((recipient, index) => {
                    return (
                      <>
                        <AddProgramForm
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          agency={selectedAgency?.value}
                          handleSearchInputOnChange={handleSearchInputOnChange}
                          index={index}
                          handleCongressmanSearch={handleCongressmanSearch}
                          recipient={recipient}
                          projectRecipients={projectRecipients}
                          handleDeleteRow={handleDeleteRow}
                          setProjectRecipients={setProjectRecipients}
                          atcOptions={atcOptions}
                          selectedProject={selectedProject?.value}
                          handleUploadedFiles={handleUploadedFiles}
                          handleDeleteFile={handleDeleteFile}
                          handleDeleteAllFiles={handleDeleteAllFiles}
                          uploadedFiles={uploadedFiles}
                          setIsFormResetted={setIsFormResetted}
                          isFormResetted={isFormResetted}
                          regionOptions={regionOptions}
                        />

                        {projectRecipients.length !== index + 1 ? <hr /> : null}
                      </>
                    );
                  })}
              </div>
              <AddRowSection>
                {!isRoleCongressman && selectedAgency?.value !== 14 ? (
                  <Button
                    className="add-row-btn mt-4"
                    onClick={() => handleAddRow()}
                  >
                    <Plus className="mr-25" />
                    {"Add Row"}
                  </Button>
                ) : null}
              </AddRowSection>
            </>
          ) : null
        ) : null}

        <Row className="m-0 pt-5 pb-5 d-flex justify-content-end mb-2">
          {action === "add" ? (
            <Col
              xs="auto"
              className="d-flex justify-content-end"
            >
              <Button
                className="btn-add-another-proj"
                onClick={() => resetForm()}
              >
                {"+  Add Another Program"}
              </Button>
            </Col>
          ) : null}

          {hasPermission("project-create") && (
            <Col
              xs="auto"
              className="d-flex justify-content-end btn-save-section"
            >
              <Button
                className="btn-save"
                onClick={() => handleSaveForm()}
                disabled={isFormValid() || isLoading}
              >
                {isLoading ? (
                  <Spinner
                    animation="border"
                    color="light"
                    size="sm"
                  />
                ) : (
                  "Save"
                )}
              </Button>
              <Col
                xs="auto"
                style={{ width: "107px" }}
                className="p-0"
              />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default AddProjectComponent;
