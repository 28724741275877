/* eslint-disable react/forbid-prop-types */

import React from "react";
import DataTable, { createTheme } from "react-data-table-component";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";

const CustomDataTable = ({
  data = [],
  columns = [],
  fixedHeader,
  noHeader,
  pagination = true,
  progressPending = false,
  paginationPerPage = 10,
  onChangePage,
  onChangeRowsPerPage,
  paginationServer,
  paginationTotalRows,
  subHeader,
  subHeaderComponent,
  progressComponent,
  defaultSortAsc,
  defaultSortField,
  onSort,
  sortServer,
  height = "auto",
  onRowClicked,
  theme,
  ...rest
}) => {
  const customStyles = {
    noData: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem 0rem",
      },
    },
  };

  const dataTableOptions = {
    className: "dataTable-custom",
    customStyles,
    data,
    columns,
    pagination: true,
    highlightOnHover: true,
    persistTableHead: true,
    progressComponent: progressComponent || (
      <div className="text-center py-1">
        <Spinner
          animation="border"
          color="dark"
          size="sm"
        />
      </div>
    ),
    ...rest,
  };

  if (fixedHeader !== undefined) {
    dataTableOptions.fixedHeader = fixedHeader;
  }
  if (noHeader !== undefined) dataTableOptions.noHeader = noHeader;
  if (pagination !== undefined) dataTableOptions.pagination = pagination;

  if (progressPending !== undefined) {
    dataTableOptions.progressPending = progressPending;
  }

  if (paginationPerPage !== undefined) {
    dataTableOptions.paginationPerPage = paginationPerPage;
  }

  if (onChangePage !== undefined) {
    dataTableOptions.onChangePage = onChangePage;
  }

  if (onChangeRowsPerPage !== undefined) {
    dataTableOptions.onChangeRowsPerPage = onChangeRowsPerPage;
  }

  if (paginationServer !== undefined) {
    dataTableOptions.paginationServer = paginationServer;
  }

  if (paginationTotalRows !== undefined) {
    dataTableOptions.paginationTotalRows = paginationTotalRows;
  }
  if (subHeader !== undefined) dataTableOptions.subHeader = subHeader;

  if (subHeaderComponent !== undefined) {
    dataTableOptions.subHeaderComponent = subHeaderComponent;
  }
  if (onSort !== undefined) dataTableOptions.onSort = onSort;
  if (sortServer !== undefined) dataTableOptions.sortServer = sortServer;

  if (defaultSortField !== undefined) {
    dataTableOptions.defaultSortField = defaultSortField;
  }

  if (defaultSortAsc !== undefined) {
    dataTableOptions.defaultSortAsc =
      `${defaultSortAsc}`?.toLowerCase() === "asc";
  }

  createTheme(
    "custom",
    {
      background: {
        default: "#F6F6F6",
      },
    },
    "light"
  );

  return (
    <DataTable
      {...dataTableOptions}
      paginationRowsPerPageOptions={[10, 25, 50, 100]}
      fixedHeader
      fixedHeaderScrollHeight={height}
      onRowClicked={onRowClicked}
      theme={theme}
    />
  );
};

CustomDataTable.propTypes = {
  onChangePage: PropTypes.oneOfType([PropTypes.func]),
  data: PropTypes.array,
  columns: PropTypes.array,
  fixedHeader: PropTypes.bool,
  noHeader: PropTypes.bool,
  pagination: PropTypes.bool,
  progressPending: PropTypes.bool,
  paginationPerPage: PropTypes.number,
  onChangeRowsPerPage: PropTypes.func,
  paginationServer: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  paginationTotalRows: PropTypes.number,
  subHeader: PropTypes.bool,
  subHeaderComponent: PropTypes.oneOfType([PropTypes.element]),
  progressComponent: PropTypes.element,
  defaultSortAsc: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  defaultSortField: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onSort: PropTypes.func,
  sortServer: PropTypes.bool,
  height: PropTypes.string,
  onRowClicked: PropTypes.func,
  theme: PropTypes.string,
};

export default CustomDataTable;
